import { createContext, useCallback, useEffect, useLayoutEffect, useState } from "react";
// import * as defaultUserData from "./userdata.json";
const defaultUserData = {};
export const DataContext = createContext();
const DataProvider = ({ children }) => {
    function useStickyState(defaultValue, key) {
        const [value, setValue] = useState(() => {
            const stickyValue = window.localStorage.getItem(key);
            return stickyValue !== null
                ? JSON.parse(stickyValue)
                : defaultValue;
        });
        useEffect(() => {
            window.localStorage.setItem(key, JSON.stringify(value));
        }, [key, value]);
        return [value, setValue];
    }
    const [dev, setDev] = useStickyState(false);
    const toggleDev = useCallback(
        (boolean) => {
            setDev(boolean);
        },
        [setDev],
    );

    /* -------------------------------------------------------------------------- */
    /*                                   ROUTES                                   */
    /* -------------------------------------------------------------------------- */
    const [routes, setRoutes] = useState(null);

    const [currentRoute, setCurrentRoute] = useState(null);
    const [prevRoute, setPrevRoute] = useState(null);
    const [nextRoute, setNextRoute] = useState(null);
    const [prevRouteDefault, setPrevRouteDefault] = useState(null);
    const [nextRouteDefault, setNextRouteDefault] = useState(null);
    const [nextTheme, setNextTheme] = useState(null);

    String.prototype.toMMSS = function () {
        let sec_num = parseInt(this, 10); // don't forget the second param
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return minutes + ':' + seconds;
    };
    String.prototype.toHHMMSS = function () {
        let sec_num = parseInt(this, 10); // don't forget the second param
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    };

    // Hamburger menu
    const [menu, setMenu] = useState(null);

    // Saved data
    const [userData, setUserData] = useStickyState(defaultUserData, "user-data");

    const resetUserData = useCallback(
        () => {
            setUserData(defaultUserData);
            // window.location.reload();
        },
        [setUserData, defaultUserData],
    );


    const setDefaultTekstsett = (t) => {
        if (t === "trinn1") {
            setUserData(d => {
                return {
                    ...d,
                    trinn1: {
                        ...d?.trinn1,
                        tekstsett: "ulv"
                    }
                };
            });
        }
        if (t === "trinn2") {
            setUserData(d => {
                return {
                    ...d,
                    trinn2: {
                        ...d?.trinn2,
                        tekstsett: "veganisme"
                    }
                };
            });
        }
        if (t === "trinn3") {
            setUserData(d => {
                return {
                    ...d,
                    trinn3: {
                        ...d?.trinn3,
                        tekstsett: "barekraft"
                    }
                };
            });
        }
    };

    /* -------------------------------------------------------------------------- */
    /*                                     DEV                                    */
    /* -------------------------------------------------------------------------- */
    const [devStatus, toggleDevStatus] = useStickyState(false, "devstatus");

    const updateTrinn = (num) => {
        setUserData(d => {
            return {
                ...d, trinn: num
            };
        });
    };
    const updateTrinnData = (trinn, obj) => {
        setUserData(d => {
            return {
                ...d,
                [trinn]: {
                    ...d[trinn],
                    ...obj
                }
            };
        });
    };
    const updateProgress = (num) => {
        setCurrentRoute(r => { return { ...r, progress: num }; });
        // setUserData(d => {
        //     return {
        //         ...d, [step]: {
        //             ...d[step],
        //             progress: num
        //         }
        //     };
        // });
    };


    const [hideHeader, setHideHeader] = useState(false);
    const [hideFooter, setHideFooter] = useState(false);
    const [pageWidth, setPageWidth] = useState(null);
    const [pageHeight, setPageHeight] = useState(null);

    const [skipBtn, setSkipBtn] = useState(null);

    const [qrHost, setQrHost] = useState(window.location.host);
    useEffect(() => {
        if (window.location.hostname === "localhost") {
            setQrHost("http://192.168.199.101:1337");
        }
    }, []);


    return (
        <DataContext.Provider
            value={{
                qrHost,
                dev,
                toggleDev,
                currentRoute,
                prevRoute,
                nextRoute,
                prevRouteDefault,
                nextRouteDefault,
                setNextRoute,
                setPrevRoute,
                setPrevRouteDefault,
                setNextRouteDefault,
                setCurrentRoute,
                nextTheme,
                setNextTheme,
                routes,
                setRoutes,

                // Alle trinn
                updateTrinn,
                updateTrinnData,

                // Trinn 1
                setDefaultTekstsett,
                defaultUserData,
                resetUserData,
                userData,
                setUserData,
                updateProgress,
                // Navigation
                menu,
                setMenu,
                // DEV
                devStatus,
                toggleDevStatus,
                useStickyState,

                // UI
                hideFooter,
                setHideFooter,
                hideHeader,
                setHideHeader,
                pageWidth,
                pageHeight,
                setPageWidth,
                setPageHeight,
                skipBtn,
                setSkipBtn
            }}
        >{children}</DataContext.Provider>
    );
};

export default DataProvider;