import { useContext, useEffect, useRef, useState, useCallback } from "react";
import { useParams, useNavigate, useSearchParams, } from "react-router-dom";
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import "./Modal.css";
import { DataContext } from "~/contexts/Data";
import Hr from "./Hr";
import Btn from "./Btn";
import { useClickAway } from "@uidotdev/usehooks";
import { VscChromeClose } from "react-icons/vsc/index.esm.js";
import QR from "~/components/QR";
import Iframe from "./Iframe";

import { useIsOverflow } from '~/hooks/useIsOverflow';
import { motion } from "framer-motion";


const Modal = ({ children, open, qrcode = false, iframe, close, noExit, solution, read }) => {
    const modalRef = useRef();
    const { path } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const { routes, currentRoute } = useContext(DataContext);
    const [current, setCurrent] = useState(null);
    const [isOpen, setIsOpen] = useState(open);
    const closeModal = useCallback(
        () => {
            if (window.location.pathname.startsWith("/modal")) {
                navigate(-1);
            } else {
                setIsOpen(false);
            }
        },
        [],
    );

    useEffect(() => {
        if (path && routes) {
            let c = routes?.filter(r => r.path.substring(1) === path);
            if (c && c[0]) setCurrent(c[0]);
        }
    }, [path, routes]);
    const modal = useClickAway(closeModal);
    let img = searchParams.get("img");
    const inner = useRef();
    const isOverflow = useIsOverflow(inner);

    let title = currentRoute?.solution?.title;

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);
    return (
        <div ref={modalRef} className={`modal-wrapper ${solution ? "modal-wrapper--solution" : ""} ${read ? "modal-wrapper--read" : ""} ${isOpen ? "modal-wrapper--open" : ""}`} >
            <div className={`modal ${iframe ? "modal--iframe" : ""} ${qrcode ? "modal--qrcode" : ""}`} ref={modal}>
                {/* <pre>{JSON.stringify(solution, null, 1)}</pre>
                <pre>{JSON.stringify(currentRoute?.solution, null, 1)}</pre>
                <pre>{JSON.stringify(pathname, null, 1)}</pre> */}
                {!noExit && <Btn className="modal__close" onClick={closeModal} wrapperProps={{ style: { transformOrigin: "top right" } }}><VscChromeClose /></Btn>}
                <div className={`modal__inner scrollbar ${isOverflow ? "modal__inner--has-scroll" : ""} `} ref={inner}>
                    {solution ? <h2 className="t-center">{title || "Tips underveis"}</h2> : null}
                    {img && <img src={img} alt={img} width="100%" height="100%" />}
                    {iframe && searchParams.get("src") && <Iframe src={searchParams.get("src")} className="modal__iframe" />}
                    {qrcode && <QR val={searchParams.get("qr")} title={searchParams.get("title")} subtitle={searchParams.get("subtitle")} />}
                    {current?.element}
                    {children}
                    {!noExit && <>
                        <Hr y={40} />
                        <Btn back={!close} onClick={closeModal} className="modal__back-btn">{close || "Tilbake"}</Btn>
                    </>}
                </div>
            </div>
        </div>
    );
};

export default Modal;