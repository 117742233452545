
import { useContext, useEffect, useState, lazy, useCallback } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// Contexts
import { DataContext } from '~/contexts/Data';

// Internal components
import Modal from '~/components/Modal';
import NotFound from '~/pages/Global/NotFound';

// Intro routes
import Home from '~/pages/00_Intro/Home';
import BeforeYouStart from '~/pages/00_Intro/BeforeYouStart';
import Oversikt from "~/pages/00_Intro/Oversikt";
import ForklaringsVideo from "~/pages/00_Intro/ForklaringsVideo";
import Introduksjon from "~/pages/00_Intro/Introduksjon";

// Global routes
import About from '~/pages/Global/About';
import Personvern from "~/pages/Global/Personvern";

// External components
import { AnimatePresence } from "framer-motion";
import Loading from './Loading';
import Hr from './Hr';
import TeksterOmGronnvasking from '../pages/03_Trinn3/TekstOmGronnvasking';
const ModelT = lazy(() => import('~/components/ModelT'));
const Box = lazy(() => import('~/components/Box'));
const ExerciseCheckboxes = lazy(() => import('~/components/ExerciseCheckboxes'));
const ScrollPage = lazy(() => import('~/components/templates/ScrollPage'));
const Page = lazy(() => import('~/components/templates/Page'));

// Trinn1 routes
const Trinn1 = lazy(() => import("~/pages/01_Trinn1/Trinn1"));
const Forklaringer = lazy(() => import("~/pages/01_Trinn1/Forklaringer"));
const Tekstsett = lazy(() => import("~/pages/01_Trinn1/Tekstsett"));
const VelgTekstsett = lazy(() => import("~/pages/01_Trinn1/VelgTekstsett"));
const Trinn1RundtTeksten = lazy(() => import("~/pages/01_Trinn1/Trinn1RundtTeksten"));
const Trinn1Kontekstualisering = lazy(() => import("~/pages/01_Trinn1/Trinn1Kontekstualisering"));
const Trinn1KontekstualiseringOppgave1 = lazy(() => import("~/pages/01_Trinn1/Trinn1KontekstualiseringOppgave1"));
const Trinn1ITeksten = lazy(() => import("~/pages/01_Trinn1/Trinn1ITeksten"));
const Trinn1Tekstreaksjon = lazy(() => import("~/pages/01_Trinn1/Trinn1Tekstreaksjon"));
const Trinn1TekstreaksjonOppgave1 = lazy(() => import("~/pages/01_Trinn1/Trinn1TekstreaksjonOppgave1"));
const Trinn1Tekstanalyse = lazy(() => import("~/pages/01_Trinn1/Trinn1Tekstanalyse"));
const Trinn1TekstanalyseOppgave1 = lazy(() => import("~/pages/01_Trinn1/Trinn1TekstanalyseOppgave1"));
const Trinn1TekstanalyseOppgave2 = lazy(() => import("~/pages/01_Trinn1/Trinn1TekstanalyseOppgave2"));
const Trinn1TekstanalyseOppgave3 = lazy(() => import("~/pages/01_Trinn1/Trinn1TekstanalyseOppgave3"));
const Trinn1Teksttolkning = lazy(() => import("~/pages/01_Trinn1/Trinn1Teksttolkning"));
const Trinn1TekstanalyseInnsikt = lazy(() => import("~/pages/01_Trinn1/Trinn1TekstanalyseInnsikt"));
const Trinn1TeksttolkningInnsikt = lazy(() => import("~/pages/01_Trinn1/Trinn1TeksttolkningInnsikt"));
const Trinn1Tekststrategier = lazy(() => import("~/pages/01_Trinn1/Trinn1Tekststrategier"));
const Trinn1TeksttolkningOppgave1 = lazy(() => import("~/pages/01_Trinn1/Trinn1TeksttolkningOppgave1"));
const Trinn1UtISamfunnet = lazy(() => import("~/pages/01_Trinn1/Trinn1UtISamfunnet"));
const Trinn1Tekstskaping = lazy(() => import("~/pages/01_Trinn1/Trinn1Tekstskaping"));
const Trinn1TekstskapingOppgave1 = lazy(() => import("~/pages/01_Trinn1/Trinn1TekstskapingOppgave1"));
const Trinn1KritiskTekstrefleksjon = lazy(() => import("~/pages/01_Trinn1/Trinn1KritiskTekstrefleksjon"));
const Trinn1KritiskTekstrefleksjonOppgave1 = lazy(() => import("~/pages/01_Trinn1/Trinn1KritiskTekstrefleksjonOppgave1"));
const Trinn1ModelDone = lazy(() => import("~/pages/01_Trinn1/Trinn1ModelDone"));
const Trinn1Summary = lazy(() => import("~/pages/01_Trinn1/Trinn1Summary"));
const Trinn1Done = lazy(() => import("~/pages/01_Trinn1/Trinn1Done"));
const Trinn1Activities = lazy(() => import("~/pages/01_Trinn1/Trinn1Activities"));

// Trinn2 routes
const Trinn2 = lazy(() => import("~/pages/02_Trinn2/Trinn2"));
const TeksterOmVeganisme = lazy(() => import("~/pages/02_Trinn2/TeksterOmVeganisme"));
const Trinn2RundtTeksten = lazy(() => import("~/pages/02_Trinn2/Trinn2RundtTeksten"));
const Trinn2Kontekstualisering = lazy(() => import("~/pages/02_Trinn2/Trinn2Kontekstualisering"));
const Trinn2KontekstualiseringOppgave1 = lazy(() => import("~/pages/02_Trinn2/Trinn2KontekstualiseringOppgave1"));
const Trinn2Tekstreaksjon = lazy(() => import("~/pages/02_Trinn2/Trinn2Tekstreaksjon"));
const Trinn2TekstreaksjonOppgave1 = lazy(() => import("~/pages/02_Trinn2/Trinn2TekstreaksjonOppgave1"));
const Trinn2ITeksten = lazy(() => import("~/pages/02_Trinn2/Trinn2ITeksten"));
const Trinn2Tekstanalyse = lazy(() => import("~/pages/02_Trinn2/Trinn2Tekstanalyse"));
const Trinn2TekstanalyseInnsikt = lazy(() => import("~/pages/02_Trinn2/Trinn2TekstanalyseInnsikt"));
const Trinn2TekstanalyseOppgave1 = lazy(() => import("~/pages/02_Trinn2/Trinn2TekstanalyseOppgave1"));
const Trinn2TekstanalyseOppgave2 = lazy(() => import("~/pages/02_Trinn2/Trinn2TekstanalyseOppgave2"));
const Trinn2TekstanalyseInnsikt2 = lazy(() => import("~/pages/02_Trinn2/Trinn2TekstanalyseInnsikt2"));
const Trinn2TekstanalyseOppgave3 = lazy(() => import("~/pages/02_Trinn2/Trinn2TekstanalyseOppgave3"));
const Trinn2Teksttolkning = lazy(() => import("~/pages/02_Trinn2/Trinn2Teksttolkning"));
const Trinn2Tekststrategier = lazy(() => import("~/pages/02_Trinn2/Trinn2Tekststrategier"));
const Trinn2TeksttolkningOppgave1 = lazy(() => import("~/pages/02_Trinn2/Trinn2TeksttolkningOppgave1"));
const Trinn2TeksttolkningOppgave2 = lazy(() => import("~/pages/02_Trinn2/Trinn2TeksttolkningOppgave2"));
const Trinn2UtISamfunnet = lazy(() => import("~/pages/02_Trinn2/Trinn2UtISamfunnet"));
const Trinn2Tekstskaping = lazy(() => import("~/pages/02_Trinn2/Trinn2Tekstskaping"));
const Trinn2TekstskapingOppgave1 = lazy(() => import("~/pages/02_Trinn2/Trinn2TekstskapingOppgave1"));
const Trinn2KritiskTekstrefleksjon = lazy(() => import("~/pages/02_Trinn2/Trinn2KritiskTekstrefleksjon"));
const Trinn2KritiskTekstrefleksjonInnsikt = lazy(() => import("~/pages/02_Trinn2/Trinn2KritiskTekstrefleksjonInnsikt"));
const Trinn2KritiskTekstrefleksjonOppgave1 = lazy(() => import("~/pages/02_Trinn2/Trinn2KritiskTekstrefleksjonOppgave1"));
const Trinn2ModelDone = lazy(() => import("~/pages/02_Trinn2/Trinn2ModelDone"));
const Trinn2Summary = lazy(() => import("~/pages/02_Trinn2/Trinn2Summary"));
const Trinn2Done = lazy(() => import("~/pages/02_Trinn2/Trinn2Done"));
const Trinn2Activities = lazy(() => import("~/pages/02_Trinn2/Trinn2Activities"));

// Trinn3 routes
const Trinn3 = lazy(() => import("~/pages/03_Trinn3/Trinn3"));
// const Trinn3GronnvaskingInnsikt = lazy(() => import("~/pages/03_Trinn3/Trinn3GronnvaskingInnsikt"));
const TeksterOmBarekraft = lazy(() => import("~/pages/03_Trinn3/TeksterOmBarekraft"));
const Trinn3RundtTeksten = lazy(() => import("~/pages/03_Trinn3/Trinn3RundtTeksten"));
const Trinn3Kontekstualisering = lazy(() => import("~/pages/03_Trinn3/Trinn3Kontekstualisering"));
const Trinn3KontekstualiseringOppgave1 = lazy(() => import("~/pages/03_Trinn3/Trinn3KontekstualiseringOppgave1"));
const Trinn3Tekstreaksjon = lazy(() => import("~/pages/03_Trinn3/Trinn3Tekstreaksjon"));
const Trinn3TekstreaksjonOppgave1 = lazy(() => import("~/pages/03_Trinn3/Trinn3TekstreaksjonOppgave1"));
const Trinn3ITeksten = lazy(() => import("~/pages/03_Trinn3/Trinn3ITeksten"));
const Trinn3Tekstanalyse = lazy(() => import("~/pages/03_Trinn3/Trinn3Tekstanalyse"));
const Trinn3TekstanalyseOppgave1 = lazy(() => import("~/pages/03_Trinn3/Trinn3TekstanalyseOppgave1"));
const Trinn3TekstanalyseOppgave2 = lazy(() => import("~/pages/03_Trinn3/Trinn3TekstanalyseOppgave2"));
const Trinn3TekstanalyseOppgave3 = lazy(() => import("~/pages/03_Trinn3/Trinn3TekstanalyseOppgave3"));
const Trinn3TekstanalyseOppgave4 = lazy(() => import("~/pages/03_Trinn3/Trinn3TekstanalyseOppgave4"));
const Trinn3Teksttolkning = lazy(() => import("~/pages/03_Trinn3/Trinn3Teksttolkning"));
const Trinn3TeksttolkningOppgave1 = lazy(() => import("~/pages/03_Trinn3/Trinn3TeksttolkningOppgave1"));
const Trinn3TeksttolkningOppgave2 = lazy(() => import("~/pages/03_Trinn3/Trinn3TeksttolkningOppgave2"));
const Trinn3TeksttolkningOppgave3 = lazy(() => import("~/pages/03_Trinn3/Trinn3TeksttolkningOppgave3"));
const Trinn3Tekststrategier = lazy(() => import("~/pages/03_Trinn3/Trinn3Tekststrategier"));
const Trinn3UtISamfunnet = lazy(() => import("~/pages/03_Trinn3/Trinn3UtISamfunnet"));
const Trinn3Tekstskaping = lazy(() => import("~/pages/03_Trinn3/Trinn3Tekstskaping"));
const Trinn3TekstskapingOppgave1 = lazy(() => import("~/pages/03_Trinn3/Trinn3TekstskapingOppgave1"));
const Trinn3TekstskapingOppgave2 = lazy(() => import("~/pages/03_Trinn3/Trinn3TekstskapingOppgave2"));
const Trinn3KritiskTekstrefleksjon = lazy(() => import("~/pages/03_Trinn3/Trinn3KritiskTekstrefleksjon"));
const Trinn3KritiskTekstrefleksjonOppgave1 = lazy(() => import("~/pages/03_Trinn3/Trinn3KritiskTekstrefleksjonOppgave1"));
const Trinn3ModelDone = lazy(() => import("~/pages/03_Trinn3/Trinn3ModelDone"));
const Trinn3Summary = lazy(() => import("~/pages/03_Trinn3/Trinn3Summary"));
const Trinn3Done = lazy(() => import("~/pages/03_Trinn3/Trinn3Done"));
const Trinn3Activities = lazy(() => import("~/pages/03_Trinn3/Trinn3Activities"));
const StepDone = lazy(() => import("~/components/templates/StepDone"));


const modalRouteObj = {
    // MODAL ROUTES
    "/om-prosjektet": {
        element: (<About />),
        modal: true
    },
    "/personvern": {
        element: (<Personvern />),
        modal: true
    },
    "/lightbox": {
        element: <></>,
        modal: true
    },
    "/qr": {
        element: <></>,
        modal: true
    },
    "/iframe": {
        element: <></>,
        modal: true
    },
    // Løsningsforslag
    "/forklaringer": {
        element: (
            <>
                <Box
                    // title={<center><strong>Fant du dette?</strong></center>}
                    p={"1rem"} style={{ maxWidth: 400 }} >
                    <center>
                        <p>
                            <strong>Her vil du kunne lese nyttig <br />info / forslag til svar.</strong>
                        </p>
                        <p>
                            Vi anbefaler deg å forsøke å svare selv først, før du ser etter tips.
                        </p>
                    </center>
                </Box>
            </>
        ),
        modal: true
    },
    // Trinn 1
    // Løsningsforslag
    "/trinn1-kontekstualisering-oppgave-1-forslag-ulv": {
        element: (
            <>
                <Box title={<center><strong>Fant du dette?</strong></center>} p={"1rem"} style={{ maxWidth: 500 }} >
                    <p><strong>Rovdyr.no</strong> er nettsida til Folkeaksjonen for en ny rovdyrpolitikk. Dette er en landsomfattende, ikke-partipolitisk aksjon som har som formål å få Stortinget til å endre dagens rovdyrpolitikk.</p>
                    <Hr y={30} border />
                    <p><strong>WWF</strong> er en global miljøorganisasjon som kjemper mot natur- og klimakrisen. Ett av fokusområdene til organisasjonen er å verne mangfoldet av arter og økosystemer.</p>
                    <Hr y={30} border />
                    <p><strong>Dyreparken</strong> er en kommersiell dyre-og fritidspark som har 117 ulike dyrearter. Dyreparken er også engasjert i arbeid for å bevare verdens dyrearter.</p>
                </Box>
            </>
        ),
        modal: true
    },
    "/trinn1-kontekstualisering-oppgave-1-forslag-yrke": {
        element: (
            <Box title={<center><strong>Fant du dette?</strong></center>} p={"1rem"} style={{ maxWidth: 500 }} >
                <p><strong>Universitetet i Sørøst-Norge (USN)</strong> er ett av 10 norske universitet. Universitetet tilbyr blant annet flere ulike profesjonsretta utdanninger som sykepleierutdanning, lærerutdanning og ingeniørutdanning.</p>
                <Hr y={30} border />
                <p><strong>Forsvaret</strong> har som hovedoppgave å forsvare Norge i en kompleks og urolig verden. Forsvarets høgskole utdanner befal og alle offiserer som får jobb i Forsvaret.</p>
            </Box>
        ),
        modal: true
    },
    "/trinn1-tekstsett": {
        element: (<Tekstsett />),
        modal: true
    },
    "/trinn1-tekstanalyse-oppgave-3-forslag-ulv": {
        element: (
            <>
                <div className='two-col-answer'>
                    <ExerciseCheckboxes childrenTop
                        boxProps={{ fullscreen: false, style: { pointerEvents: "none", userSelect: "none" } }}
                        columns={[
                            {
                                title: "adjektiver",
                            },
                            {
                                subtitle: "dyreparken.no",
                                objKey: "dyreparken",
                                thumbnail: {
                                    src: "/media/dyreparken-no-header.jpg",
                                }
                            },
                            {
                                subtitle: "rovdyr.org",
                                objKey: "rovdyr",
                                thumbnail: {
                                    src: "/media/rovdyr-org-header.jpg",
                                }
                            },
                            {
                                subtitle: "wwf.no",
                                objKey: "wwf",
                                thumbnail: {
                                    src: "/media/wwf-ulv-i-norge-header.jpg",
                                }
                            }
                        ]}
                        rows={[
                            { title: "Menneskevennlig", checked: ["wwf"] },
                            { title: "Farlig", checked: ["rovdyr"] },
                            { title: "Vill", checked: ["dyreparken", "rovdyr"] },
                            { title: "Tam", checked: ["wwf"] },
                            { title: "Glad", checked: ["dyreparken"] },
                            { title: "Fri", checked: ["dyreparken"] },
                            { title: "Kosete", checked: ["wwf"] },
                            { title: "Levende", checked: ["dyreparken", "wwf"] },
                            { title: "Uskaddeliggjort", checked: ["rovdyr"] },
                            { title: "Fanget", checked: ["rovdyr"] },
                            { title: "Skadelig", checked: ["rovdyr"] },
                        ]}
                    >
                        <p className='h2 mb-0'>Løsningsforslag</p>
                        <p className='ingress mb-0'>Vi plasserte kryssene på denne måten  </p>
                        <Hr y={".5rem"} />
                        <Hr y={"3rem"} border />
                    </ExerciseCheckboxes>
                    <Box title={<center><strong>Fant du dette?</strong></center>} p={"1rem"} style={{ maxWidth: 500 }} >
                        <p><strong>Dyreparkulven</strong> framstilles som fri, vill og glad for å vise at ulvene lever gode ulve-liv i Dyreparken. Dette passer godt med ønsket om å bidra til en sunn bestand av ulver i Europa.</p>
                        <Hr y={30} border />
                        <p><strong>WWF-ulvene</strong> ser ut som kosedyr for å framstille ulven som et sympatisk dyr. En slik framstillingsmåte passer til budskapet om at ulven er kritisk truet og trenger vern.</p>
                        <Hr y={30} border />
                        <p><strong>Ulvemotstanderne</strong> viser ulven som et farlig dyr. En slik framstillingsmåte er tilpasset tanken om at Norge har et rovdyrproblem og at Norge trenger en strengere rovdyrpolitikk.</p>
                    </Box>
                </div>
            </>
        ),
        modal: true
    },
    "/trinn1-tekstanalyse-oppgave-3-forslag-yrke": {
        element: (
            <>
                <div className='two-col-answer'>
                    <div>
                        <div><img alt="Tre sykepleiere" src="/media/usn-blikk.jpg" style={{ width: "100%", aspectRatio: "16 / 9", maxWidth: "500px" }} /></div>
                        <div><img alt="Militære tropper fra Forsvaret" src="/media/forsvaret-blikk.jpg" style={{ width: "100%", aspectRatio: "16 / 9", maxWidth: "500px" }} /></div>
                    </div>
                    <Box title={<center><strong>Fant du dette?</strong></center>} p={"1rem"} style={{ maxWidth: 500 }} >
                        <p>I bildet av de ni offiserene ser alle i samme retning &ndash; rett fram. Slik er det ikke i sykehussituasjonen. Den ene sykepleierens blikk m&oslash;ter pasientens, men den andre sykepleieren har blikket rettet mot noe som skjer med pasientens kropp.</p>

                        <p><strong>Blikkretningene</strong> sier noe om kommunikasjon og samhandling. Offiserene ser konsentrert i fellesskap mot noe langt framme som vi ikke ser. Kanskje en fiende? De lar seg ikke distrahere. Sykepleierne har pasienten i fokus og viser ogs&aring; at de retter oppmerksomheten mot det faglige sykepleie-arbeidet.</p>

                        <p>Kanskje er det daglige arbeidet i Forsvaret mer preget av relasjoner enn det dette bildet viser, men framstillingsmåten gjør at Forsvaret virker sterkt og konsentrert. Det viser også forsvaret som høytidelig og flott. Dette bryter med de erfaringene vi har med virkelige krigshandlinger. Bildet av sykepleierene framstiller ro og omsorg. Det er slik vi ønsker å møte helsevesenet når vi har behov for hjelp. Kanskje er dette også et glansbilde som utelater stress og krevende pasientbehandling.</p>
                    </Box>
                </div>
            </>
        ),
        modal: true
    },
    // Trinn 2
    // Løsningsforslag
    "/trinn2-kontekstualisering-oppgave-1-forslag": {
        element: (
            <>
                <Box
                    // title={<center><strong>Fant du dette?</strong></center>}
                    p={"1rem"} style={{ maxWidth: 500 }} >
                    <p>
                        <strong>Sophie Elise</strong> er en påvirker i sosiale medier som ble kjent gjennom bloggen sin allerede som 17 år gammel (i 2011). Hun er både et AS (Sophie Elise AS) som både blogger og finnes på ulike sosiale medier, og som selger sine egne skjønnhetsprodukter, og også en person som heter Sophie Elise Isaksen. Hun henvender seg til et ungt publikum og skriver blogg og sosiale medieposter og lager podcast om temaer som skjønnhet, mote og trender, men også mental helse, rus og livsstil.
                    </p>
                    <Hr y={30} border />
                    <p>
                        <strong>ung.forskning.no</strong> er et nettsted under forskning.no som formidler forskning til barn og ungdom. Sakene skrives stort sett av utdannede journalister, men iblant også av ungdomsjournalister, som i dette tilfellet. Nettstedet henvender seg til unge, men også til lærere som leter etter materiale til bruk i klasserommet.
                    </p>
                </Box>
            </>
        ),
        modal: true
    },
    "/trinn2-tekstsett": {
        element: (<TeksterOmVeganisme />),
        modal: true
    },
    "/trinn2-tekstanalyse-oppgave-1-forslag": {
        element: (
            <>
                <Box
                    title={<center><strong>Vi har tolket det på denne måten: </strong></center>}
                    p={"1rem"} style={{ maxWidth: 500 }} >
                    <p>
                        <strong>Bloggteksten</strong> inneholder to deltakere. Sophie Elise er den viktigste av disse. Hun snakker hele tiden om sine egne holdninger, vaner og perspektiver. Den andre deltakeren kalles «noen» i første linje, og impliseres i hele teksten; det er Sophie Elises lesere. </p>
                    <Hr y={30} border />
                    <p>
                        <strong>Artikkelen</strong> inneholder en lang rekke deltakere, for eksempel «unge i Norge», «OsloMet» og «Birger Svihus, professor i ernæring».
                    </p>
                </Box>
            </>
        ),
        modal: true
    },
    "/trinn2-tekstanalyse-oppgave-2-forslag": {
        element: (
            <>
                <Box
                    // title={<center><strong>Vi har tolket det på denne måten: </strong></center>}
                    p={"1rem"} style={{ maxWidth: 500 }} >
                    <p>
                        <strong>Bloggteksten</strong> bruker jeg og meg veldig ofte, mens artikkelen bruker ganske få og mer varierte pronomen. Legg merke til at ordet seg i bloggteksten refererer til en generell aktør, ikke en konkret person. På samme måte refererer du i <strong>artikkelen</strong> også til en generell person – du kunne blitt erstattet med man i artikkelen.
                    </p>
                </Box>
            </>
        ),
        modal: true
    },
    "/trinn2-tekstanalyse-oppgave-3-forslag": {
        element: (
            <>
                <Box
                    title={<center><strong>Vi har tolket det på denne måten: </strong></center>}
                    p={"1rem"} style={{ maxWidth: 500 }} >
                    <p>
                        Vi mener at bloggteksten implisitt sier at et vegansk kosthold er noe bra, noe som man vil oppnå, mens artikkelen sier at det å ikke spise kjøtt er noe mange etterstreber og noe som kan være mer eller mindre bærekraftig.
                    </p>
                    <p>
                        Vi merket oss også at tekstene bruker et ulikt antall kilder. I bloggteksten er Sophie Elise selv eneste kilde fordi hun skriver om sine egne erfaringer. Artikkelen bruker flere kilder, for eksempel Birger Svihus, professor på Norges miljø- og biovitenskapelige universitet, og sier ingenting om hva artikkelforfatteren, Ida Sofie Frøyland, selv mener.
                    </p>
                </Box>
            </>
        ),
        modal: true
    },
    "/trinn2-teksttolkning-oppgave-1-forslag": {
        element: (
            <>
                <Box
                    // title={<center><strong>Vi har tolket det på denne måten: </strong></center>}
                    p={"1rem"} style={{ maxWidth: 500 }} >
                    <p className='mb-0'>
                        <strong>Bloggteksten</strong> innebærer individualisering fordi Sophie Elise setter sine individuelle vurderinger i forgrunnen.
                    </p>
                    <Hr y={30} border />
                    <p className='mb-0'>
                        <strong>Artikkelen</strong> innebærer ekspertmarkering for eksempel når den henviser til det at Birger Svihus er professor.
                    </p>
                    <Hr y={30} border />
                    <p className='mb-0'>
                        <strong>Bloggteksten</strong> driver med implisitt ansvarstildeling når den antyder at forbrukeren har et ansvar for å velge vegansk med mindre man har en unnskyldning.
                    </p>
                    <Hr y={30} border />
                    <p className='mb-0'>
                        <strong>Artikkelen</strong> driver med ansvarstildeling når den setter lit til forskningens evne til å besvare spørsmålet om hvorvidt veganisme kan redde verden.
                    </p>
                </Box>
            </>
        ),
        modal: true
    },
    "/trinn2-teksttolkning-oppgave-2-forslag": {
        element: (
            <>
                <Box
                    title={<center><strong>Vi har tolket det på denne måten: </strong></center>}
                    p={"1rem"} style={{ maxWidth: 500 }} >
                    <p className='mb-0'>
                        Mange av koblingene mellom påstand og tekst kan
                        diskuteres. Derfor er oppgaven et godt utgangspunkt for klassesamtale. Man kan for eksempel argumentere for at begge tekstene beskriver veganisme som noe bra, og at kjøttspising er problematisk. Den tydeligste
                        forskjellen er kanskje at bloggteksten er tydelig personlig, mens artikkelen beskriver uenighet mellom ulike deltakere.
                    </p>
                </Box>
            </>
        ),
        modal: true
    },
    // Trinn 3
    // Løsningsforslag
    "/trinn3-tekstsett": {
        element: (<TeksterOmBarekraft />),
        modal: true
    },
    "/trinn3-tekstanalyse-oppgave-1-forslag": {
        element: (
            <>
                <Box
                    title={<center><strong>Vi har tolket det på denne måten: </strong></center>}
                    p={"1rem"} style={{ maxWidth: 500 }} >
                    <p className='mb-0'><strong>Deltakeren p&aring; bildet</strong> er ung og vakker og hun holder opp frisk frukt, som kan symbolisere sunnhet og styrke.</p>
                    <Hr y={30} border />
                    <p className='mb-0'><strong>Hvitt</strong> er en dominerende <strong>farge</strong> i bildene, en farge som ofte forbindes med uskyld og renhet.</p>
                    <Hr y={30} border />
                    <p className='mb-0'><strong>Blikket til deltakeren</strong> er rettet mot kamera i det ene bildet, og mot appelsinen i det andre, og derfor kan vi si at det f&oslash;rste bildet henvender seg til leseren, mens det andre bildet retter oppmerksomheten mot frukten som <strong>objekt.</strong></p>
                    <Hr y={30} border />
                    <p className='mb-0'><strong>H&amp;M</strong> vil kanskje framst&aring; som uskyldige i milj&oslash;sp&oslash;rsm&aring;let, og som bidragende til sunnhet og styrke gjennom produksjon med naturmaterialer som r&aring;vare?</p>
                </Box>
            </>
        ),
        modal: true
    },
    "/trinn3-tekstanalyse-oppgave-2-forslag": {
        element: (
            <>
                <div className='two-col-answer'>
                    <div>

                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <th style={{ width: 140 }}>vi/oss/vår</th>
                                    <td>
                                        <div className='highlighted-tags'>14</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ width: 140 }}>de/dem/deres</th>
                                    <td>
                                        <div className='highlighted-tags'>2</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ width: 140 }}>jeg/meg/min</th>
                                    <td>
                                        <div className='highlighted-tags'>0</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ width: 140 }}>du/deg/din</th>
                                    <td>
                                        <div className='highlighted-tags'>0</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ width: 140 }}>han/seg/sin</th>
                                    <td>
                                        <div className='highlighted-tags'>0</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Box
                        title={<><strong>Vi la merke til dette:</strong></>}
                        p={"1rem"} style={{ maxWidth: 850 }} >
                        <p>
                            <strong>Bærekraft hos H&M</strong><br />
                            Planeten <span class="vi">vår</span> har ikke nok naturressurser til at <span class="vi">vi</span> kan fortsette slik <span class="vi">vi</span> gjør nå. Derfor trenger <span class="vi">vi</span> å endre prosessene våre.
                        </p>
                        <p>
                            Motebransjen er en av <span class="de">de</span> mest forurensende bransjene i verden, og en av <span class="de">de</span> største vannforbrukerne.
                            Ved å investere i nye teknologiske løsninger håper <span class="vi">vi</span> å endre måten <span class="vi">vi</span> lager, bruker og kvitter <span class="vi">oss</span> med klær på.
                            Det må bli slutt på utslitte jeans som husholdningsavfall og på å være avhengig av fabrikker med høyt vannforbruk.
                            <span class="vi">Vi</span> ønsker i stedet å avskaffe avfall og la materialer flyte i sirkler.
                        </p>
                        <p>
                            For å gjøre dette mulig støtter <span class="vi">vi</span> selskaper som Re:newcell, Worn Again, Ambercycle og Infinited Fiber,
                            innovatører som er like ivrige som <span class="vi">oss</span> etter å endre motebransjen. <span class="vi">Vi</span> oppmuntrer også nyskapende
                            ideer for å støtte klimapositiv mote (et uttrykk som betyr å fjerne mer drivhusgass fra atmosfæren enn <span class="vi">vi</span> skaper i hele verdikjeden <span class="vi">vår</span>).
                            En innovasjon <span class="vi">vi</span> virkelig er stolte av å være en del av er Looop: verdens første resirkuleringssystem i butikk, som forvandler gamle klær til nye.
                            Looop ble installert i en av butikkene våre i Stockholm i 2020.
                            <br /> <br /> Når <span class="du">du</span> shopper hos oss, er <span class="du">du</span> med på å gjøre ideer om til en realitet.
                        </p>
                    </Box>
                </div>
            </>
        ),
        modal: true
    },
    "/trinn3-tekstanalyse-oppgave-3-forslag": {
        element: (
            <>
                <div className='two-col-answer'>
                    <Box
                        title={<><strong>La du merke til dette?</strong></>}
                        p={"1rem"} style={{ maxWidth: 550 }} >
                        <p>
                            <strong>Bærekraft hos H&M</strong><br />
                            Setningen «Det må bli slutt på utslitte jeans som husholdningsavfall og på å være avhengig av fabrikker med høyt vannforbruk» er helt uten pronomen.
                            Det betyr at det også er ganske uklart i setningen hvem det er som skal slutte å behandle jeans som husholdningsavfall og være avhengig av fabrikker
                            med høyt vannforbruk – er det forbrukeren, H&M, eller klesprodusenter generelt?
                        </p>
                        <p>
                            I klasserommet er det fint å diskutere hva disse ulike versjonene innebærer, og hvilken elevene tror H&M mente.
                        </p>
                    </Box>
                    <Box
                        title={<><strong>Se hva som skjer hvis vi putter inn ulike deltakere i setningen:</strong></>}
                        p={"1rem"} style={{ maxWidth: 750 }} >
                        <p className="mb-0"><strong>H&amp;M</strong> m&aring; slutte &aring; behandle utslitte jeans som husholdningsavfall og &aring; v&aelig;re avhengig av fabrikker med h&oslash;yt vannforbruk.</p>
                        <Hr y={30} border />
                        <p className="mb-0"><strong>Kunder</strong> i klesbutikker m&aring; slutte &aring; behandle utslitte jeans som husholdningsavfall og &aring; v&aelig;re avhengig av fabrikker med h&oslash;yt vannforbruk.</p>
                        <Hr y={30} border />
                        <p className="mb-0"><strong>Klesprodusenter</strong> m&aring; slutte &aring; behandle utslitte jeans som husholdningsavfall og &aring; v&aelig;re avhengig av fabrikker med h&oslash;yt vannforbruk.</p>
                        <Hr y={30} border />
                        <p className="mb-0"><strong>Vi mennesker</strong> m&aring; slutte &aring; behandle utslitte jeans som husholdningsavfall og &aring; v&aelig;re avhengig av fabrikker med h&oslash;yt vannforbruk.</p>
                    </Box>
                </div>
            </>
        ),
        modal: true
    },
    "/trinn3-tekstanalyse-oppgave-4-forslag": {
        element: (
            <>
                <Box
                    title={<><strong>Vi har tolket det på denne måten:</strong></>}
                    p={"1rem"} style={{ maxWidth: 550 }} >
                    <p>
                        <strong>H&M</strong> snakker om noe som er problematisk og vanskelig, nemlig å produsere billige klær uten å forurense og bruke for mange ressurser.
                        Men tonen i teksten er positiv, og det er fullt av positivt ladede ord. Dette kan få avsenderen til å framstå som entusiastisk og glad.
                        Og du vil vel heller kjøpe klær hos en entusiastisk og engasjert selger, enn hos en som klager over hvor vanskelig de har det?
                    </p>
                </Box>
            </>
        ),
        modal: true
    },
    "/trinn3-teksttolkning-oppgave-1-forslag": {
        element: (
            <>
                <Box
                    style={{ maxWidth: 1400 }}
                    title={<center><strong>Vi har tolket det på denne måten:</strong></center>}
                    p={"1rem"}>
                    <table className="w-100">
                        <tr>
                            <th style={{ backgroundColor: "#E8FFEC" }}>
                                <div className='ui-row' style={{ justifyContent: "space-between" }}>
                                    <p className='t-center mb-0'><strong>Dette hevder H&M</strong></p>
                                    <img alt="H&M logo" src="/media/trinn3/hm-logo.svg" width={50} style={{ display: "block" }} />
                                </div>
                            </th>
                            <th style={{ backgroundColor: "#FFE8E8" }}>
                                <div className='ui-row' style={{ justifyContent: "space-between" }}>
                                    <p className='t-center mb-0'><strong>Dette hevder H&M ikke</strong></p>
                                    <img alt="H&M logo" src="/media/trinn3/hm-logo.svg" width={50} style={{ display: "block" }} />
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <td style={{ overflow: "hidden", verticalAlign: "top", backgroundColor: "#E8FFEC" }}>
                                <p><strong>Det &aring; gj&oslash;re klesproduksjon milj&oslash;vennlig uten &aring; nedskalere, er mulig.</strong><br /><strong>JA,</strong> dette kan vi si at H&amp;M hevder implisitt i teksten n&aring;r de sier at skal finne nye m&aring;ter &aring; produsere p&aring;, ikke at de skal produsere mindre.</p>
                                <Hr y={30} border />
                                <p><strong>H&amp;M blir milj&oslash;vennlige n&aring;r de investerer i bedrifter som er milj&oslash;vennlige.</strong> <br /><strong>JA,</strong> dette kan vi si at H&amp;M hevder. Alle tiltakene som beskrives i teksten baserer seg p&aring; at andre enn H&amp;M selv skal foreta endringer.</p>
                                <Hr y={30} border />
                                <p><strong>H&amp;M er entusiastiske og motiverte for &aring; jobbe med b&aelig;rekraft.</strong> <br /><strong>JA,</strong> dette kan vi si at H&amp;M hevder implisitt n&aring;r de bruker positiv verdiladning gjennomg&aring;ende i teksten.</p>
                                <Hr y={30} border />
                                <p><strong>H&amp;M-produkter gir deg b&aring;de et ungt og friskt utseende og ren samvittighet.</strong> <br /><strong>JA,</strong> gjennom bildene antyder H&amp;M at de representerer et ungt, friskt utseende. B&aring;de gjennom bruk av fargen hvit og gjennom verbaltekstens bruk av pronomenet vi, som antyder at H&amp;Ms samarbeidspartnere har gjort jobben for deg, slik at du trygt kan handle hos dem, sier de at samvittigheten din kan v&aelig;re ren.</p>
                            </td>
                            <td style={{ overflow: "hidden", verticalAlign: "top", backgroundColor: "#FFE8E8" }}>
                                <p><strong>H&amp;M skal slippe ut mindre CO2 enn de gj&oslash;r i dag.</strong> <br /><strong>NEI,</strong> H&amp;M sier at de skal kompensere for mer CO2 enn de slipper ut, men ikke at de skal redusere utslippene.</p>
                                <Hr y={30} border />
                                <p><strong>H&amp;M tar imot brukte kl&aelig;r til resirkulering.</strong> <br /><strong>NEI,</strong> H&amp;M har if&oslash;lge teksten installert et system for dette i &eacute;n butikk, men sier ingenting om planer for dette videre eller i andre butikker.</p>
                                <Hr y={30} border />
                                <p><strong>Det er vanskelig &aring; tenke seg klesproduksjon i storskala som kan v&aelig;re b&aring;de b&aelig;rekraftig og l&oslash;nnsom.</strong> <br /><strong>NEI,</strong> dette sier ikke H&amp;M, selv om man lett kan argumentere for at utsagnet stemmer.</p>
                            </td>
                        </tr>
                    </table>
                </Box>
            </>
        ),
        modal: true
    },
    "/trinn3-teksttolkning-oppgave-2-forslag": {
        element: (
            <>
                <Box
                    title={<center><strong>Vi har tolket det på denne måten:</strong></center>}
                    p={"1rem"} style={{ maxWidth: 550 }} >
                    <p>H&amp;M-tekstens <strong>h&oslash;ye frekvens</strong> av pronomenet <strong>&laquo;vi&raquo;</strong> kan gj&oslash;re at de framst&aring;r som medlemmer av ulike grupper, for eksempel &laquo;vi mennesker&raquo;, &laquo;vi klesprodusenter&raquo; eller &laquo;vi som er opptatt av milj&oslash;vern&raquo;. Dette kan v&aelig;re en <strong>strategi for &aring; skape en f&oslash;lelse av n&aelig;rhet</strong> til mange ulike mottakere.</p>
                    <p>N&aring;r de sier &laquo;Planeten v&aring;r har ikke nok naturressurser til at vi kan fortsette slik vi gj&oslash;r n&aring;. Derfor trenger vi &aring; endre prosessene v&aring;re&raquo; virker det som om &laquo;viet&raquo; er st&oslash;rre enn bare H&amp;M, siden det ikke bare er H&amp;M sin planet. Da kan teksten ogs&aring; gi ansvaret for &aring; bli bedre p&aring; milj&oslash;vern til en st&oslash;rre gruppe, og slippe &aring; ta det selv.</p>
                </Box>
            </>
        ),
        modal: true
    },
    "/trinn3-teksttolkning-gronnvaskingsplakaten": {
        element: (<TeksterOmGronnvasking />),
        modal: true
    },

};
const routesObj = {
    "/": {
        thumb: "/media/screens/forside.jpg",
        pageProps: { vCenter: true }, element: (<Home />),
        showFooterLine: true,
    },
    /* -------------------------------------------------------------------------- */
    /*                                    INTRO                                   */
    /* -------------------------------------------------------------------------- */
    "/foer-du-begynner": {
        thumb: "/media/screens/foer-du-begynner.jpg",
        pageProps: { container: false, lowPadding: true }, element: (<ScrollPage><BeforeYouStart /></ScrollPage>),
        showFooterLine: true,
    }, "/forklaringer-1": {
        thumb: "/media/screens/forklaringer-1.jpg",
        element: (<Forklaringer step={1} />),
    },
    "/forklaringer-2": {
        thumb: "/media/screens/forklaringer-2.jpg",
        skipBtn: { children: "Hopp over forklaringer", skipTo: "/trinn1/" },
        element: (<Forklaringer step={2} />),
    },
    "/forklaringer-3": {
        thumb: "/media/screens/forklaringer-3.jpg",
        skipBtn: { children: "Hopp over forklaringer", skipTo: "/trinn1/" },
        element: (<Forklaringer step={3} />),
        progressDots: [{ label: "Forklaringer 3", path: "/forklaringer-3" }, { label: "Forklaringer 4", path: "/forklaringer-4" }],
        progress: 1.5
    },
    "/forklaringer-4": {
        thumb: "/media/screens/forklaringer-4.jpg",
        skipBtn: { children: "Hopp over forklaringer", skipTo: "/trinn1/" },
        element: (<Forklaringer step={4} />),
        progressDots: [{ label: "Forklaringer 3", path: "/forklaringer-3" }, { label: "Forklaringer 4", path: "/forklaringer-4" }],
        progress: 2,
        solution: "/modal/forslag/forklaringer",
    },
    "/forklaringer-5": {
        thumb: "/media/screens/forklaringer-5.jpg",
        skipBtn: { children: "Hopp over forklaringer", skipTo: "/trinn1/" },
        tekstsett: "/modal/les/trinn1-tekstsett?t=inherit&minimal=true",
        element: (<Forklaringer step={5} />),
    },
    "/forklaringer-6": {
        thumb: "/media/screens/forklaringer-6.jpg",
        skipBtn: { children: "Hopp over forklaringer", skipTo: "/trinn1/" },
        element: (<Forklaringer step={6} />),
    },
    "/oversikt": {
        thumb: "/media/screens/oversikt.jpg",
        pageProps: { container: false, lowPadding: true }, element: (<ScrollPage><Oversikt /></ScrollPage>),
        showFooterLine: true,
    },
    "/forklaringsvideo": {
        thumb: "/media/screens/forklaringsvideo.jpg",
        element: (<ForklaringsVideo />),
        showFooterLine: true,
    },

    /* -------------------------------------------------------------------------- */
    /*                                   TRINN 1                                  */
    /* -------------------------------------------------------------------------- */

    "/trinn1/": {
        thumb: "/media/screens/trinn1.jpg",
        element: (<Trinn1 />),
    },
    "/trinn1/introduksjon": {
        // SLETTES
        thumb: "/media/screens/introduksjon.jpg",
        pageProps: { vCenter: true }, element: (<Introduksjon />),
        showFooterLine: true,
    },
    "/trinn1/velg-tekstsett": {
        thumb: "/media/screens/trinn1-tekstsett.jpg",
        next: null,
        element: (<VelgTekstsett />),
    },
    "/trinn1/tekstsett": {
        thumb: "/media/screens/trinn1-tekstsett.jpg",
        progress: 1,
        progressLabel: "Les tekster",
        changeTekstsett: ["ulv", "yrke"],
        progressPath: "/trinn1/tekstsett?t=inherit&read=true",
        element: (<Tekstsett />),
    },
    "/trinn1/rundt-teksten": {
        thumb: "/media/screens/trinn1-rundt-teksten.jpg",
        progress: 1.4,
        prev: "/trinn1/tekstsett?t=yrke&read=true",
        pageProps: { container: false }, element: (<Trinn1RundtTeksten />),
    },
    "/trinn1/kontekstualisering": {
        thumb: "/media/screens/trinn1-kontekstualisering.jpg",
        progress: 1.6,
        element: (<Trinn1Kontekstualisering />),
    },
    "/trinn1/kontekstualisering/oppgave-1": {
        thumb: "/media/screens/trinn1-kontekstualisering-oppgave-1.jpg",
        progress: 1.8,
        changeTekstsett: ["ulv", "yrke"],
        tekstsett: "/modal/les/trinn1-tekstsett?t=inherit&minimal=true",
        solution: { "ulv": "/modal/forslag/trinn1-kontekstualisering-oppgave-1-forslag-ulv", "yrke": "/modal/forslag/trinn1-kontekstualisering-oppgave-1-forslag-yrke" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn1KontekstualiseringOppgave1 />),
    },
    "/trinn1/kontekstualisering-ferdig": {
        thumb: "/media/screens/trinn1-kontekstualisering-ferdig.jpg",
        progress: 2,
        progressLabel: <center><ModelT size="6rem" step={1} singleStep checkmarks={false} /><br />Kontekstualisering</center>,
        progressPath: "/trinn1/kontekstualisering",
        element: (<Trinn1Kontekstualisering done />),
    },
    "/trinn1/tekstreaksjon": {
        thumb: "/media/screens/trinn1-tekstreaksjon.jpg",
        progress: 2.3,
        element: (<Trinn1Tekstreaksjon />),
    },
    "/trinn1/tekstreaksjon/oppgave-1": {
        thumb: "/media/screens/trinn1-tekstreaksjon-oppgave-1.jpg",
        progress: 2.5,
        changeTekstsett: ["ulv", "yrke"],
        tekstsett: "/modal/les/trinn1-tekstsett?t=inherit&minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn1TekstreaksjonOppgave1 />),
    },
    "/trinn1/tekstreaksjon-ferdig": {
        thumb: "/media/screens/trinn1-tekstreaksjon-ferdig.jpg",
        progress: 3,
        progressLabel: <center><ModelT size="6rem" step={2} singleStep checkmarks={false} /><br />Tekstreaksjon</center>,
        progressPath: "/trinn1/tekstreaksjon",
        element: (<Trinn1Tekstreaksjon done />),
    },
    "/trinn1/i-teksten": {
        thumb: "/media/screens/trinn1-i-teksten.jpg",
        progress: 3.1,
        pageProps: { container: false }, element: (<Trinn1ITeksten />),
    },
    "/trinn1/tekstanalyse": {
        thumb: "/media/screens/trinn1-tekstanalyse.jpg",
        progress: 3.2,
        element: (<Trinn1Tekstanalyse />),
    },
    "/trinn1/tekstanalyse/oppgave-1": {
        thumb: "/media/screens/trinn1-tekstanalyse-oppgave-1.jpg",
        progress: 3.3,
        changeTekstsett: ["ulv", "yrke"],
        tekstsett: "/modal/les/trinn1-tekstsett?t=inherit&minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn1TekstanalyseOppgave1 />),
    },
    "/trinn1/tekstanalyse/oppgave-2": {
        thumb: "/media/screens/trinn1-tekstanalyse-oppgave-2.jpg",
        progress: 3.4,
        changeTekstsett: ["ulv", "yrke"],
        tekstsett: "/modal/les/trinn1-tekstsett?t=inherit&minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn1TekstanalyseOppgave2 />),
    },
    "/trinn1/tekstanalyse/innsikt": {
        thumb: "/media/screens/trinn1-tekstanalyse-innsikt.jpg",
        progress: 3.5,
        pageProps: { container: false, lowPadding: true }, element: (<Trinn1TekstanalyseInnsikt />),
    },
    "/trinn1/tekstanalyse/oppgave-3": {
        thumb: "/media/screens/trinn1-tekstanalyse-oppgave-3.jpg",
        progress: 3.7,
        changeTekstsett: ["ulv", "yrke"],
        tekstsett: "/modal/les/trinn1-tekstsett?t=inherit&minimal=true",
        solution: { "ulv": "/modal/forslag/trinn1-tekstanalyse-oppgave-3-forslag-ulv", "yrke": "/modal/forslag/trinn1-tekstanalyse-oppgave-3-forslag-yrke" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn1TekstanalyseOppgave3 />),
    },
    "/trinn1/tekstanalyse-ferdig": {
        thumb: "/media/screens/trinn1-tekstanalyse-ferdig.jpg",
        progress: 4,
        progressLabel: <center><ModelT size="6rem" step={3} singleStep checkmarks={false} /><br />Tekstanalyse</center>,
        progressPath: "/trinn1/tekstanalyse",
        element: (<Trinn1Tekstanalyse done />),
    },
    "/trinn1/teksttolkning": {
        thumb: "/media/screens/trinn1-teksttolkning.jpg",
        progress: 4.2,
        element: (<Trinn1Teksttolkning />),
    },
    "/trinn1/teksttolkning/innsikt": {
        thumb: "/media/screens/trinn1-teksttolkning-innsikt.jpg",
        progress: 4.4,
        pageProps: { container: false, lowPadding: true }, element: (<Trinn1TeksttolkningInnsikt />),
    },
    "/trinn1/teksttolkning/les-tekststrategier": {
        thumb: "/media/screens/trinn1-teksttolkning-les-tekststrategier.jpg",
        progress: 4.6,
        pageProps: { container: false, lowPadding: true }, element: (<Trinn1Tekststrategier />),
    },
    "/trinn1/teksttolkning/oppgave-1": {
        thumb: "/media/screens/trinn1-teksttolkning-oppgave-1.jpg",
        progress: 4.8,
        changeTekstsett: ["ulv", "yrke"],
        tekstsett: "/modal/les/trinn1-tekstsett?t=inherit&minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn1TeksttolkningOppgave1 />),
    },
    "/trinn1/teksttolkning-ferdig": {
        thumb: "/media/screens/trinn1-teksttolkning-ferdig.jpg",
        progress: 5,
        progressLabel: <center><ModelT size="6rem" step={4} singleStep checkmarks={false} /><br />Teksttolkning</center>,
        progressPath: "/trinn1/teksttolkning",
        element: (<Trinn1Teksttolkning done />),
    },
    "/trinn1/ut-i-samfunnet": {
        thumb: "/media/screens/trinn1-ut-i-samfunnet.jpg",
        progress: 5.3,
        pageProps: { container: false }, element: (<Trinn1UtISamfunnet />),
    },
    "/trinn1/tekstskaping": {
        thumb: "/media/screens/trinn1-tekstskaping.jpg",
        progress: 5.6,
        element: (<Trinn1Tekstskaping />),
    },
    "/trinn1/tekstskaping/oppgave-1": {
        thumb: "/media/screens/trinn1-tekstskaping-oppgave-1.jpg",
        progress: 5.8,
        changeTekstsett: ["ulv", "yrke"],
        tekstsett: "/modal/les/trinn1-tekstsett?t=inherit&minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn1TekstskapingOppgave1 />),
    },
    "/trinn1/tekstskaping-ferdig": {
        thumb: "/media/screens/trinn1-tekstskaping-ferdig.jpg",
        progress: 6,
        progressPath: "/trinn1/tekstskaping",
        progressLabel: <center><ModelT size="6rem" step={5} singleStep checkmarks={false} /><br />Tekstskaping</center>,
        element: (<Trinn1Tekstskaping done />),
    },
    "/trinn1/kritisk-tekstrefleksjon": {
        thumb: "/media/screens/trinn1-kritisk-tekstrefleksjon.jpg",
        progress: 6.2,
        element: (<Trinn1KritiskTekstrefleksjon />),
    },
    "/trinn1/kritisk-tekstrefleksjon/oppgave-1": {
        thumb: "/media/screens/trinn1-kritisk-tekstrefleksjon-oppgave-1.jpg",
        progress: 6.5,
        changeTekstsett: ["ulv", "yrke"],
        tekstsett: "/modal/les/trinn1-tekstsett?t=inherit&minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn1KritiskTekstrefleksjonOppgave1 />),
    },
    "/trinn1/kritisk-tekstrefleksjon-ferdig": {
        thumb: "/media/screens/trinn1-kritisk-tekstrefleksjon-ferdig.jpg",
        progress: 7,
        progressLabel: <center><ModelT size="6rem" step={6} singleStep checkmarks={false} /><br />Kritisk tekstrefleksjon</center>,
        progressPath: "/trinn1/kritisk-tekstrefleksjon",
        element: (<Trinn1KritiskTekstrefleksjon done />),
    },
    "/trinn1/modell-ferdig": {
        thumb: "/media/screens/trinn1-modell-ferdig.jpg",
        progress: 7.5,
        element: (<Trinn1ModelDone />),
    },
    "/trinn1/oppsummering": {
        thumb: "/media/screens/trinn1-oppsummering.jpg",
        progress: 7.7,
        nextSuccess: true,
        nextLabel: "Fullfør",
        pageProps: { noPaddingLeft: true, container: false, lowPadding: true }, element: (<Trinn1Summary />),
    },
    "/trinn1/ferdig": {
        thumb: "/media/screens/trinn1-ferdig.jpg",
        progress: 8,
        // hideNextBtn: true,
        progressModifierClass: "progress--done progress-done-1",
        element: (<Trinn1Done />),
    },
    "/trinn1/aktiviteter-til-klasserommet": {
        thumb: "/media/screens/trinn1-aktiviteter-til-klasserommet.jpg",
        pageProps: { noPaddingLeft: true, container: false, lowPadding: true }, element: (<Trinn1Activities />),
    },

    /* -------------------------------------------------------------------------- */
    /*                                   TRINN 2                                  */
    /* -------------------------------------------------------------------------- */
    "/trinn2/": {
        thumb: "/media/screens/trinn2.jpg",
        pageProps: { container: false }, element: (<Trinn2 />),
    },
    "/trinn2/les-tekster": {
        thumb: "/media/screens/trinn2-les-tekster.jpg",
        progress: 1,
        progressLabel: "Les tekster",
        progressPath: "/trinn2/les-tekster?read=true",
        element: (<TeksterOmVeganisme />),
    },
    "/trinn2/rundt-teksten": {
        thumb: "/media/screens/trinn2-rundt-teksten.jpg",
        progress: 1.4,
        prev: "/trinn2/les-tekster?read=true",
        pageProps: { container: false }, element: (<Trinn2RundtTeksten />),
    },
    "/trinn2/kontekstualisering": {
        thumb: "/media/screens/trinn2-kontekstualisering.jpg",
        progress: 1.6,
        element: (<Trinn2Kontekstualisering />),
    },
    "/trinn2/kontekstualisering/oppgave-1": {
        thumb: "/media/screens/trinn2-kontekstualisering-oppgave-1.jpg",
        progress: 1.8,
        solution: {
            "veganisme": "/modal/forslag/trinn2-kontekstualisering-oppgave-1-forslag",
            title: "Tips underveis",
        },
        tekstsett: "/modal/les/trinn2-tekstsett?minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn2KontekstualiseringOppgave1 />),
    },
    "/trinn2/kontekstualisering-ferdig": {
        thumb: "/media/screens/trinn2-kontekstualisering-ferdig.jpg",
        progress: 2,
        progressLabel: <center><ModelT size="6rem" step={1} singleStep checkmarks={false} /><br />Kontekstualisering</center>,
        progressPath: "/trinn2/kontekstualisering",
        element: (<Trinn1Kontekstualisering done />),
    },
    "/trinn2/tekstreaksjon": {
        thumb: "/media/screens/trinn2-tekstreaksjon.jpg",
        progress: 2.3,
        element: (<Trinn2Tekstreaksjon />),
    },
    "/trinn2/tekstreaksjon/oppgave-1": {
        thumb: "/media/screens/trinn2-tekstreaksjon-oppgave-1.jpg",
        progress: 2.5,
        tekstsett: "/modal/les/trinn2-tekstsett?minimal=true",
        // solution: { "ulv": "/modal/forslag/trinn1-tekstreaksjon-oppgave-1-forslag-ulv", "yrke": "/modal/forslag/trinn1-tekstreaksjon-oppgave-1-forslag-yrke" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn2TekstreaksjonOppgave1 />),
    },
    "/trinn2/tekstreaksjon-ferdig": {
        thumb: "/media/screens/trinn2-tekstreaksjon-ferdig.jpg",
        progress: 3,
        progressLabel: <center><ModelT size="6rem" step={2} singleStep checkmarks={false} /><br />Tekstreaksjon</center>,
        progressPath: "/trinn2/tekstreaksjon",
        element: (<Trinn2Tekstreaksjon done />),
    },
    "/trinn2/i-teksten": {
        thumb: "/media/screens/trinn2-i-teksten.jpg",
        progress: 3.1,
        pageProps: { container: false }, element: (<Trinn2ITeksten />),
    },
    "/trinn2/tekstanalyse": {
        thumb: "/media/screens/trinn2-tekstanalyse.jpg",
        progress: 3.2,
        element: (<Trinn2Tekstanalyse />),
    },
    "/trinn2/tekstanalyse/innsikt": {
        thumb: "/media/screens/trinn2-tekstanalyse-innsikt.jpg",
        progress: 3.4,
        pageProps: { container: false, lowPadding: true }, element: (<Trinn2TekstanalyseInnsikt />),
    },
    "/trinn2/tekstanalyse/oppgave-1": {
        thumb: "/media/screens/trinn2-tekstanalyse-oppgave-1.jpg",
        progress: 3.5,
        tekstsett: "/modal/les/trinn2-tekstsett?minimal=true",
        solution: { "veganisme": "/modal/forslag/trinn2-tekstanalyse-oppgave-1-forslag" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn2TekstanalyseOppgave1 />),
    },
    "/trinn2/tekstanalyse/oppgave-2": {
        thumb: "/media/screens/trinn2-tekstanalyse-oppgave-2.jpg",
        progress: 3.6,
        tekstsett: "/modal/les/trinn2-tekstsett?minimal=true",
        solution: { "veganisme": "/modal/forslag/trinn2-tekstanalyse-oppgave-2-forslag" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn2TekstanalyseOppgave2 />),
    },
    "/trinn2/tekstanalyse/innsikt-2": {
        thumb: "/media/screens/trinn2-tekstanalyse-innsikt-2.jpg",
        progress: 3.7,
        pageProps: { container: false, lowPadding: true }, element: (<Trinn2TekstanalyseInnsikt2 />),
    },
    "/trinn2/tekstanalyse/oppgave-3": {
        thumb: "/media/screens/trinn2-tekstanalyse-oppgave-3.jpg",
        progress: 3.8,
        tekstsett: "/modal/les/trinn2-tekstsett?minimal=true",
        solution: { "veganisme": "/modal/forslag/trinn2-tekstanalyse-oppgave-3-forslag" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn2TekstanalyseOppgave3 />),
    },
    "/trinn2/tekstanalyse-ferdig": {
        thumb: "/media/screens/trinn2-tekstanalyse-ferdig.jpg",
        progress: 4,
        progressLabel: <center><ModelT size="6rem" step={3} singleStep checkmarks={false} /><br />Tekstanalyse</center>,
        progressPath: "/trinn2/tekstanalyse",
        element: (<Trinn2Tekstanalyse done />),
    },
    "/trinn2/teksttolkning": {
        thumb: "/media/screens/trinn2-teksttolkning.jpg",
        progress: 4.2,
        element: (<Trinn2Teksttolkning />),
    },
    "/trinn2/teksttolkning/les-tekststrategier": {
        thumb: "/media/screens/trinn2-teksttolkning-les-tekststrategier.jpg",
        progress: 4.4,
        pageProps: { container: false, lowPadding: true }, element: (<Trinn2Tekststrategier />),
    },
    "/trinn2/teksttolkning/oppgave-1": {
        thumb: "/media/screens/trinn2-teksttolkning-oppgave-1.jpg",
        progress: 4.7,
        tekstsett: "/modal/les/trinn2-tekstsett?minimal=true",
        solution: { "veganisme": "/modal/forslag/trinn2-teksttolkning-oppgave-1-forslag" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn2TeksttolkningOppgave1 />),
    },
    "/trinn2/teksttolkning/oppgave-2": {
        thumb: "/media/screens/trinn2-teksttolkning-oppgave-2.jpg",
        progress: 4.8,
        tekstsett: "/modal/les/trinn2-tekstsett?minimal=true",
        solution: { "veganisme": "/modal/forslag/trinn2-teksttolkning-oppgave-2-forslag" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn2TeksttolkningOppgave2 />),
    },
    "/trinn2/teksttolkning-ferdig": {
        thumb: "/media/screens/trinn2-teksttolkning-ferdig.jpg",
        progress: 5,
        progressPath: "/trinn2/teksttolkning",
        element: (<Trinn2Teksttolkning done />),
        progressLabel: <center><ModelT size="6rem" step={4} singleStep checkmarks={false} /><br />Teksttolkning</center>,
    },
    "/trinn2/ut-i-samfunnet": {
        thumb: "/media/screens/trinn2-ut-i-samfunnet.jpg",
        progress: 5.4,
        pageProps: { container: false }, element: (<Trinn2UtISamfunnet />),
    },
    "/trinn2/tekstskaping": {
        thumb: "/media/screens/trinn2-tekstskaping.jpg",
        progress: 5.6,
        element: (<Trinn2Tekstskaping />),
    },
    "/trinn2/tekstskaping/oppgave-1": {
        thumb: "/media/screens/trinn2-tekstskaping-oppgave-1.jpg",
        progress: 5.8,
        tekstsett: "/modal/les/trinn2-tekstsett?minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn2TekstskapingOppgave1 />),
    },
    "/trinn2/tekstskaping-ferdig": {
        thumb: "/media/screens/trinn2-tekstskaping-ferdig.jpg",
        progress: 6,
        progressPath: "/trinn2/tekstskaping",
        element: (<Trinn2Tekstskaping done />),
        progressLabel: <center><ModelT size="6rem" step={5} singleStep checkmarks={false} /><br />Tekstskaping</center>,
    },
    "/trinn2/kritisk-tekstrefleksjon": {
        thumb: "/media/screens/trinn2-kritisk-tekstrefleksjon.jpg",
        progress: 6.3,
        element: (<Trinn2KritiskTekstrefleksjon />),
    },
    "/trinn2/kritisk-tekstrefleksjon/innsikt": {
        thumb: "/media/screens/trinn2-kritisk-tekstrefleksjon-innsikt.jpg",
        progress: 6.5,
        pageProps: { container: false, lowPadding: true }, element: (<Trinn2KritiskTekstrefleksjonInnsikt />),
    },
    "/trinn2/kritisk-tekstrefleksjon/oppgave-1": {
        thumb: "/media/screens/trinn2-kritisk-tekstrefleksjon-oppgave-1.jpg",
        progress: 6.7,
        tekstsett: "/modal/les/trinn2-tekstsett?minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn2KritiskTekstrefleksjonOppgave1 />),
    },
    "/trinn2/kritisk-tekstrefleksjon-ferdig": {
        thumb: "/media/screens/trinn2-kritisk-tekstrefleksjon-ferdig.jpg",
        progress: 7,
        progressPath: "/trinn2/kritisk-tekstrefleksjon",
        element: (<Trinn2KritiskTekstrefleksjon done />),
        progressLabel: <center><ModelT size="6rem" step={6} singleStep checkmarks={false} /><br />Tekstrefleksjon</center>,
    },
    "/trinn2/modell-ferdig": {
        thumb: "/media/screens/trinn2-modell-ferdig.jpg",
        progress: 7.5,
        element: (<Trinn2ModelDone />),
    },
    "/trinn2/oppsummering": {
        thumb: "/media/screens/trinn2-oppsummering.jpg",
        progress: 7.7,
        nextSuccess: true,
        nextLabel: "Fullfør",
        pageProps: { noPaddingLeft: true, container: false, lowPadding: true }, element: (<Trinn2Summary />),
    },
    "/trinn2/ferdig": {
        thumb: "/media/screens/trinn2-ferdig.jpg",
        progress: 8,
        // hideNextBtn: true,
        progressModifierClass: "progress--done progress-done-2",
        element: (<Trinn2Done />),
    },
    "/trinn2/aktiviteter-til-klasserommet": {
        thumb: "/media/screens/trinn2-aktiviteter-til-klasserommet.jpg",
        pageProps: { noPaddingLeft: true, container: false, lowPadding: true }, element: (<Trinn2Activities />),
    },

    /* -------------------------------------------------------------------------- */
    /*                                   TRINN 3                                  */
    /* -------------------------------------------------------------------------- */
    "/trinn3/": {
        thumb: "/media/screens/trinn3.jpg",
        pageProps: { container: false }, element: (<Trinn3 />),
    },
    // "/trinn3/gronnvaskingsplakaten": {
    //     // FJERNES
    //     thumb: "/media/screens/trinn3-gronnvaskingsplakaten.jpg",
    //     pageProps: { container: false, lowPadding: true }, element: (<Trinn3GronnvaskingInnsikt />),
    // },
    "/trinn3/les-tekster": {
        thumb: "/media/screens/trinn3-les-tekster.jpg",
        progress: 1,
        progressLabel: "Les tekster",
        progressPath: "/trinn3/les-tekster?read=true",
        element: (<TeksterOmBarekraft />),
    },
    "/trinn3/rundt-teksten": {
        thumb: "/media/screens/trinn3-rundt-teksten.jpg",
        progress: 1.4,
        prev: "/trinn3/les-tekster?read=true",
        pageProps: { container: false }, element: (<Trinn3RundtTeksten />),
    },
    "/trinn3/kontekstualisering": {
        thumb: "/media/screens/trinn3-kontekstualisering.jpg",
        progress: 1.6,
        element: (<Trinn3Kontekstualisering />),
    },
    "/trinn3/kontekstualisering/oppgave-1": {
        thumb: "/media/screens/trinn3-kontekstualisering-oppgave-1.jpg",
        progress: 1.8,
        solution: {
            "veganisme": "/modal/forslag/trinn3-kontekstualisering-oppgave-1-forslag",
            title: "Tips underveis",
        },
        tekstsett: "/modal/les/trinn3-tekstsett?minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn3KontekstualiseringOppgave1 />),
    },
    "/trinn3/kontekstualisering-ferdig": {
        thumb: "/media/screens/trinn3-kontekstualisering-ferdig.jpg",
        progress: 2,
        progressLabel: <center><ModelT size="6rem" step={1} singleStep checkmarks={false} /><br />Kontekstualisering</center>,
        progressPath: "/trinn3/kontekstualisering",
        element: (<Trinn2Kontekstualisering done />),
    },
    //
    // Oppgave: reaksjon
    //
    "/trinn3/tekstreaksjon": {
        thumb: "/media/screens/trinn3-tekstreaksjon.jpg",
        progress: 2.3,
        element: (<Trinn3Tekstreaksjon />),
    },
    "/trinn3/tekstreaksjon/oppgave-1": {
        thumb: "/media/screens/trinn3-tekstreaksjon-oppgave-1.jpg",
        progress: 2.5,
        tekstsett: "/modal/les/trinn3-tekstsett?minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn3TekstreaksjonOppgave1 />),
    },
    "/trinn3/tekstreaksjon-ferdig": {
        thumb: "/media/screens/trinn3-tekstreaksjon-ferdig.jpg",
        progress: 3,
        progressLabel: <center><ModelT size="6rem" step={2} singleStep checkmarks={false} /><br />Tekstreaksjon</center>,
        progressPath: "/trinn3/tekstreaksjon",
        element: (<Trinn3Tekstreaksjon done />),
    },
    "/trinn3/i-teksten": {
        thumb: "/media/screens/trinn3-i-teksten.jpg",
        progress: 3.1,
        pageProps: { container: false }, element: (<Trinn3ITeksten />),
    },
    "/trinn3/tekstanalyse": {
        thumb: "/media/screens/trinn3-tekstanalyse.jpg",
        progress: 3.2,
        element: (<Trinn3Tekstanalyse />),
    },
    "/trinn3/tekstanalyse/oppgave-1": {
        thumb: "/media/screens/trinn3-tekstanalyse-oppgave-1.jpg",
        progress: 3.5,
        tekstsett: "/modal/les/trinn3-tekstsett?minimal=true",
        solution: { "barekraft": "/modal/forslag/trinn3-tekstanalyse-oppgave-1-forslag" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn3TekstanalyseOppgave1 />),
    },
    "/trinn3/tekstanalyse/oppgave-2": {
        thumb: "/media/screens/trinn3-tekstanalyse-oppgave-2.jpg",
        progress: 3.6,
        tekstsett: "/modal/les/trinn3-tekstsett?minimal=true",
        solution: { "barekraft": "/modal/forslag/trinn3-tekstanalyse-oppgave-2-forslag" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn3TekstanalyseOppgave2 />),
    },
    "/trinn3/tekstanalyse/oppgave-3": {
        thumb: "/media/screens/trinn3-tekstanalyse-oppgave-3.jpg",
        progress: 3.7,
        tekstsett: "/modal/les/trinn3-tekstsett?minimal=true",
        solution: { "barekraft": "/modal/forslag/trinn3-tekstanalyse-oppgave-3-forslag" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn3TekstanalyseOppgave3 />),
    },
    "/trinn3/tekstanalyse/oppgave-4": {
        thumb: "/media/screens/trinn3-tekstanalyse-oppgave-4.jpg",
        progress: 3.8,
        tekstsett: "/modal/les/trinn3-tekstsett?minimal=true",
        solution: { "barekraft": "/modal/forslag/trinn3-tekstanalyse-oppgave-4-forslag" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn3TekstanalyseOppgave4 />),
    },
    "/trinn3/tekstanalyse-ferdig": {
        thumb: "/media/screens/trinn3-tekstanalyse-ferdig.jpg",
        progress: 4,
        element: (<Trinn3Tekstanalyse done />),
    },
    "/trinn3/teksttolkning": {
        thumb: "/media/screens/trinn3-teksttolkning.jpg",
        progress: 4.2,
        element: (<Trinn2Teksttolkning />),
    },
    "/trinn3/teksttolkning/les-tekststrategier": {
        thumb: "/media/screens/trinn3-teksttolkning-les-tekststrategier.jpg",
        progress: 4.4,
        pageProps: { container: false, lowPadding: true }, element: (<Trinn3Tekststrategier />),
    },
    "/trinn3/teksttolkning/oppgave-1": {
        thumb: "/media/screens/trinn3-teksttolkning-oppgave-1.jpg",
        progress: 4.5,
        tekstsett: "/modal/les/trinn3-tekstsett?minimal=true",
        solution: { "barekraft": "/modal/forslag/trinn3-teksttolkning-oppgave-1-forslag" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn3TeksttolkningOppgave1 />),
    },
    "/trinn3/teksttolkning/oppgave-2": {
        thumb: "/media/screens/trinn3-teksttolkning-oppgave-2.jpg",
        progress: 4.6,
        tekstsett: "/modal/les/trinn3-tekstsett?minimal=true",
        solution: { "barekraft": "/modal/forslag/trinn3-teksttolkning-oppgave-2-forslag" },
        pageProps: { container: false, lowPadding: true }, element: (<Trinn3TeksttolkningOppgave2 />),
    },
    // "/trinn3/gronnvaskingsplakaten": {
    //     // LEGGES TIL
    //     thumb: "/media/screens/trinn3-gronnvaskingsplakaten.jpg",
    //     pageProps: { container: false, lowPadding: true }, element: (<Trinn3GronnvaskingInnsikt />),
    // },

    "/trinn3/teksttolkning/gronnvaskingsplakaten": {
        thumb: "/media/screens/trinn3-teksttolkning-gronnvaskingsplakaten.jpg",
        progress: 4.8,
        element: (<TeksterOmGronnvasking />),
    },
    "/trinn3/teksttolkning/oppgave-3": {
        thumb: "/media/screens/trinn3-teksttolkning-oppgave-3.jpg",
        progress: 4.9,
        tekstsett: "/modal/les/trinn3-teksttolkning-gronnvaskingsplakaten?minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn3TeksttolkningOppgave3 />),
    },
    //
    // Oppgave 2: teksttolkning
    //
    //
    // Oppgave 3: teksttolkning
    //
    "/trinn3/teksttolkning-ferdig": {
        thumb: "/media/screens/trinn3-teksttolkning-ferdig.jpg",
        progress: 5,
        progressPath: "/trinn3/teksttolkning",
        element: (<Trinn3Teksttolkning done />),
        progressLabel: <center><ModelT size="6rem" step={4} singleStep checkmarks={false} /><br />Teksttolkning</center>,
    },
    "/trinn3/ut-i-samfunnet": {
        thumb: "/media/screens/trinn3-ut-i-samfunnet.jpg",
        progress: 5.3,
        pageProps: { container: false }, element: (<Trinn3UtISamfunnet />),
    },
    "/trinn3/tekstskaping": {
        thumb: "/media/screens/trinn3-tekstskaping.jpg",
        progress: 5.6,
        element: (<Trinn3Tekstskaping />),
    },
    "/trinn3/tekstskaping/oppgave-1": {
        thumb: "/media/screens/trinn3-tekstskaping-oppgave-1.jpg",
        progress: 5.7,
        tekstsett: "/modal/les/trinn3-tekstsett?minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn3TekstskapingOppgave1 />),
    },
    "/trinn3/tekstskaping/oppgave-2": {
        thumb: "/media/screens/trinn3-tekstskaping-oppgave-2.jpg",
        progress: 5.8,
        tekstsett: "/modal/les/trinn3-tekstsett?minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn3TekstskapingOppgave2 />),
    },
    //
    // Oppgave 2: tekstskaping
    //
    "/trinn3/tekstskaping-ferdig": {
        thumb: "/media/screens/trinn3-tekstskaping-ferdig.jpg",
        progress: 6,
        progressPath: "/trinn3/tekstskaping",
        progressLabel: <center><ModelT size="6rem" step={5} singleStep checkmarks={false} /><br />Tekstskaping</center>,
        element: (<Trinn3Tekstskaping done />),
    },
    "/trinn3/kritisk-tekstrefleksjon": {
        thumb: "/media/screens/trinn3-kritisk-tekstrefleksjon.jpg",
        progress: 6.2,
        element: (<Trinn3KritiskTekstrefleksjon />),
    },
    "/trinn3/kritisk-tekstrefleksjon/oppgave-1": {
        thumb: "/media/screens/trinn3-kritisk-tekstrefleksjon-oppgave-1.jpg",
        progress: 6.5,
        tekstsett: "/modal/les/trinn3-tekstsett?minimal=true",
        pageProps: { container: false, lowPadding: true }, element: (<Trinn3KritiskTekstrefleksjonOppgave1 />),
    },
    "/trinn3/kritisk-tekstrefleksjon-ferdig": {
        thumb: "/media/screens/trinn3-kritisk-tekstrefleksjon-ferdig.jpg",
        progress: 7,
        progressLabel: <center><ModelT size="6rem" step={6} singleStep checkmarks={false} /><br />Kritisk tekstrefleksjon</center>,
        progressPath: "/trinn3/kritisk-tekstrefleksjon",
        element: (<Trinn3KritiskTekstrefleksjon done />),
    },
    "/trinn3/modell-ferdig": {
        thumb: "/media/screens/trinn3-modell-ferdig.jpg",
        progress: 7.5,
        element: (<Trinn3ModelDone />),
    },
    "/trinn3/oppsummering": {
        thumb: "/media/screens/trinn3-oppsummering.jpg",
        progress: 7.7,
        nextSuccess: true,
        nextLabel: "Fullfør",
        pageProps: { noPaddingLeft: true, container: false, lowPadding: true }, element: (<Trinn3Summary />),
    },
    "/trinn3/ferdig": {
        thumb: "/media/screens/trinn3-ferdig.jpg",
        progress: 8,
        // hideNextBtn: true,
        progressModifierClass: "progress--done progress-done-3",
        element: (<Trinn3Done />),
    },
    "/trinn3/aktiviteter-til-klasserommet": {
        thumb: "/media/screens/trinn3-aktiviteter-til-klasserommet.jpg",
        pageProps: { noPaddingLeft: true, container: false, lowPadding: true }, element: (<Trinn3Activities />),
    },
    "/ferdig": {
        thumb: "/media/screens/trinn3-ferdig.jpg",
        element: (
            <StepDone title="Helt ferdig!" subtitle="Gratulerer, du har gjennomført hele kurset."
                prices={[
                    {
                        label: "Premie trinn 1",
                        href: "/trinn1/aktiviteter-til-klasserommet",
                        props: {
                            target: "_blank"
                        }
                    },
                    {
                        label: "Premie trinn 2",
                        href: "/trinn2/aktiviteter-til-klasserommet",
                        props: {
                            target: "_blank"
                        }
                    },
                    {
                        label: "Premie trinn 3",
                        href: "/trinn3/aktiviteter-til-klasserommet",
                        props: {
                            target: "_blank"
                        }
                    }
                ]}
            />),
    },
};

const AllRoutes = () => {
    const { setCurrentRoute, setPrevRoute, setNextRoute, setPrevRouteDefault, setNextRouteDefault, routes, setNextTheme, setRoutes, updateTrinn, setDefaultTekstsett } = useContext(DataContext);

    const keys = Object.keys(routesObj);

    const getPrev = (current) => {
        let nextIndex = keys.indexOf(current) - 1;
        return keys[nextIndex];
    };
    const getNext = (current) => {
        let nextIndex = keys.indexOf(current) + 1;
        return keys[nextIndex];
    };
    const [loadingRoutes, setLoadingRoutes] = useState(true);
    useEffect(() => {
        let allRoutes = Object.entries({ ...routesObj, ...modalRouteObj }).map(keyVal => { return { ...keyVal[1], path: keyVal[0] }; });
        setRoutes(allRoutes);
        // console.log(Object.entries(routesObj)?.map(keyVal => keyVal[0]));
        setTimeout(() => {
            setLoadingRoutes(false);
        }, 500);
    }, []);
    const location = useLocation();
    const pathname = location.pathname;
    const previousLocation = location.state?.previousLocation;

    const updateCurrentRoute = useCallback(
        (r) => {
            setCurrentRoute(r);
            let p = r.hasOwnProperty("prev") ? r.prev : getPrev(pathname);
            let n = r.hasOwnProperty("next") ? r.next : getNext(pathname);
            setPrevRoute(p);
            setNextRoute(n);
            setPrevRouteDefault(p);
            setNextRouteDefault(n);
        },
        [pathname],
    );
    useEffect(() => {
        if (routes?.length > 0) {
            let current = routes?.find(r => r.path === pathname);
            if (current) {
                updateCurrentRoute(current);
            }
        }
    }, [routes, pathname]);


    useEffect(() => {
        if (typeof setNextTheme === "function") {
            setNextTheme(null);
        }
    }, [pathname]);
    useEffect(() => {
        if (pathname.startsWith("/trinn1")) {
            updateTrinn({ num: 1, path: "/trinn1" });
        } else if (pathname.startsWith("/trinn2")) {
            updateTrinn({ num: 2, path: "/trinn2" });
            setDefaultTekstsett("trinn2");
        } else if (pathname.startsWith("/trinn3")) {
            updateTrinn({ num: 3, path: "/trinn3" });
            setDefaultTekstsett("trinn3");
        }
        else updateTrinn(null);
    }, [pathname]);

    return (
        <>
            <AnimatePresence  >
                <Routes key="all-routes" location={previousLocation || location} >
                    {/* <Route path="*" element={<Loading />} /> */}
                    {routes?.map((r, index) => <Route  {...r} element={<Page {...r.pageProps}>{r.element}</Page>} key={JSON.stringify(r) + index + "route"} />)}
                    <Route key="loading/not-found" path="*" element={<>{loadingRoutes ? <Loading /> : <Page><NotFound /></Page>}</>} />
                    {/* <Route exact path="/modalhome" element={<ModalBg />} /> */}
                </Routes>
                {previousLocation && (
                    <Routes key="modal-routes" >
                        <Route key={pathname + "/modal"} path="/modal/:path" element={<Modal />} />
                        <Route key={pathname + "/modal/qr"} path="/modal/qr" element={<Modal qrcode close="Lukk" />} />
                        <Route key={pathname + "/modal/les"} path="/modal/les/:path" element={<Modal read close="Lukk" />} />
                        <Route key={pathname + "/modal/forslag"} path="/modal/forslag/:path" element={<Modal solution close="Lukk" />} />
                        <Route key={pathname + "/modal/iframe"} path="/modal/iframe" element={<Modal noExit iframe close="Lukk" />} />
                    </Routes>
                )}
            </AnimatePresence>
        </>
    );
};

export default AllRoutes;