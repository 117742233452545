
// Styles
import "./TeksterOmGronnvasking.css";
import { ReadCard } from "~/components/ReadCard";
import Hr from "~/components/Hr";
import { useContext } from "react";
import { DataContext } from "~/contexts/Data";
import { useLocation, useSearchParams } from "react-router-dom";
import Box from "~/components/Box";
import ListenToText from "../../components/ListenToText";
const TeksterOmGronnvasking = () => {
    const { qrHost } = useContext(DataContext);
    let [searchParams, setSearchParams] = useSearchParams();
    let minimal = searchParams.get("minimal");
    const { pathname } = useLocation();
    if (pathname.startsWith("/modal") && !pathname.startsWith("/modal/les")) return null;

    return (
        <div className={`w-100 ${minimal ? "tekster-om-gronnvasking--minimal" : ""}`}>
            <div><h1 className='w-100 t-left'>Grønnvaskingsplakaten {minimal && "og H&M"}</h1></div>
            <div className="tekster-om-gronnvasking__grid">
                <ReadCard
                    img={{ src: "/media/trinn3/gronnvaskingsplakaten-header.jpg" }}
                    title="Grønnvaskingsplakaten"
                    print
                    doc="/media/trinn3/gronnvaskingsplakaten--print.pdf"
                    qr={`/modal/qr?qr=${qrHost}/media/trinn3/gronnvaskingsplakaten--mobile.jpg&title=Grønnvaskingsplakaten&subtitle=gronnvasking.no`}
                >
                    <small>gronnvasking.no</small>
                </ReadCard>

                {minimal && <ReadCard
                    img={{ src: "/media/trinn3/hm-header.jpg" }}
                    title="Bærekraft hos H&M"
                    print
                    doc="/media/trinn3/hm-barekraft--print.pdf"
                    qr={`/modal/qr?qr=${qrHost}/media/trinn3/hm-barekraft--mobile.jpg&title=Bærekraft hos H%26M?&subtitle=hm.com`}
                >
                    <small>hm.com</small>
                </ReadCard>}

                {!minimal && <Box p="2.5rem" className="tekster-om-barekraft__instructions" h100 style={{ height: "100%" }} wrapperProps={{ height: "100%" }}>



                    {/*                     
                    https://ttsfree.com/
    
                    Innsikt, grønnvaskingsplakaten.
                    Fordi det er ganske lønnsomt å markedsføre seg selv som et bærekraftig alternativ, har en gruppe klimaengasjerte organisasjoner laget grønnvaskingsplakaten.
                    
                    Grønnvaskingsplakaten består av 10 råd om hvordan man unngår grønnvasking. Plakaten er undertegnet av en rekke norske selskaper og bedrifter. Vi skal bruke denne plakaten som bakgrunn for å analysere en markedsføringstekst. Vi vil derfor begynne med å lese grønnvaskingsplakaten.
                    Teksten vi skal jobbe med i dette trinnet er en markedsføringstekst fra den gigantiske internasjonale kleskjeden Hennes og Mauritz (H&M).
                     */}
                    <p className="ingress">Innsikt i Grønnvaskingsplakaten</p>
                    <ListenToText audio="/media/voice/trinn3-innsikt-gronnvaskingsplakaten.mp3">
                        <p>Fordi det er ganske l&oslash;nnsomt &aring; markedsf&oslash;re seg selv som et b&aelig;rekraftig alternativ, har en gruppe klimaengasjerte organisasjoner laget <strong>gr&oslash;nnvaskingsplakaten</strong>.</p>
                        <p>Gr&oslash;nnvaskingsplakaten best&aring;r av <strong>10 r&aring;d</strong> om hvordan man <strong>unng&aring;r gr&oslash;nnvasking.</strong> Plakaten er undertegnet av en rekke norske selskaper og bedrifter. Vi skal bruke denne plakaten som bakgrunn for &aring; analysere en markedsf&oslash;ringstekst. Vi vil derfor begynne med &aring; lese gr&oslash;nnvaskingsplakaten.</p>

                    </ListenToText>
                </Box>}
            </div>
            <Hr y={30} />
            <div className="w-100 t-left tekster-om-gronnvasking__terms">
                <p>
                    <small>
                        Alle tekster vises som skjermbilder som ble lagret da vi startet utviklingen av Tekstkritisk.no.
                        Dette ble gjort for å sikre innholdet i tekstene. Tekstene kan være utdatert og skal derfor kun benyttes som eksempeltekster på
                        Tekstkritisk.no
                    </small>
                </p>
            </div>
        </div>
    );
};

export default TeksterOmGronnvasking;