
const Iframe = ({ style, ...props }) => {
    return (
        <>
            <iframe
                onLoad={(object) => {
                    object.style.height = object?.contentWindow?.document?.documentElement?.scrollHeight + 'px';
                }}
                style={{
                    height: "100%",
                    width: "100%"
                }}
                scrolling="hidden"
                frameBorder={0}
                {...props}
            />
        </>
    );
};

export default Iframe;