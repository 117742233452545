// Styles
import './Circle.css';

const Circle = ({ children, shadow, className = "", size, style = {} }) => {
  if(size) style["--size"] = size
  return (
    <>
      <div
        style={{...style}}
        className={`circle ${className} ${shadow ? "circle--shadow": ""}`}
      >
        {children}
      </div>
    </>
  );
};
export default Circle;
