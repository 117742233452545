
// Styles
import "./Hr.css";

const Hr = ({ x = "100%", y = "2px", t, b, border = false, style = {}, ...props }) => {
    return (
        <hr className={`hr ${border ? "hr--border" : ""}`} style={{ height: y, width: x, marginTop: t, marginBottom: b, ...style }} {...props} />
    );
};

export default Hr;