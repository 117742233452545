
import uiaLogo from "~/assets/uia-logo.svg";
import usnLogo from "~/assets/usn-logo.svg";
import forskningsraadetLogo from "~/assets/forskningsraadet-logo.svg";


// Styles
import "./PartnerLogos.css";
import A from "./A";
import { motion } from "framer-motion";

const PartnerLogos = () => {
    return (
        <div className="partner__logos">
            <motion.div whileTap={{ scale: 0.95 }}><A href="https://www.uia.no/" className="hover-scale"><img alt="UiA logo" src={uiaLogo} className="partner__logos__logo" width="145.6px" height="33.02px" /></A></motion.div>
            <motion.div whileTap={{ scale: 0.95 }}><A href="https://www.usn.no/" className="hover-scale"><img alt="USN logo" src={usnLogo} className="partner__logos__logo" width="143.21px" height="33px" /></A></motion.div>
            <motion.div whileTap={{ scale: 0.95 }}><A href="https://www.forskningsradet.no/" className="hover-scale"><img alt="Forskningsrådet logo" src={forskningsraadetLogo} className="partner__logos__logo" width="181px" height="33px" /></A></motion.div>
        </div>
    );
};

export default PartnerLogos;