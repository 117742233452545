import React, { useEffect, useRef, useState } from 'react';
import "./Audio.css";
import { IoPlaySharp, IoPauseSharp } from "react-icons/io5/index.esm.js";
import { RxReset } from "react-icons/rx/index.esm.js";
import Hr from './Hr';
import { motion } from "framer-motion";
const Audio = ({ src, returnPercentage = () => { } }) => {

    const player = useRef();
    const [playing, setPlaying] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const convertTime = (num) => {
        let seconds = parseInt(num);
        let minutes = parseInt(seconds / 60);
        seconds -= minutes * 60;
        const hours = parseInt(minutes / 60);
        minutes -= hours * 60;
        if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
        return `${String(hours).padStart(2, 0)}:${minutes}:${String(
            seconds % 60
        ).padStart(2, 0)}`;
    };

    const timeUpdate = (event) => {
        setCurrentTime(event.target.currentTime);
        setPercentage(event.target.currentTime / event.target.duration * 100);
    };

    useEffect(() => {
        returnPercentage(percentage);
    }, [percentage]);

    useEffect(() => {
        let p = player.current;
        if (p) {
            const onLoaded = (e) => {
                setDuration(e.target.duration);
            };
            p.addEventListener("loadedmetadata", onLoaded);
            return () => {
                p.removeEventListener("loadedmetadata", onLoaded);
            };
        }
    }, [player]);

    const [started, setStarted] = useState(false);


    return (
        <>
            <audio controls ref={player} onPlay={() => { setPlaying(true); setStarted(true); }} onPause={() => setPlaying(false)} onTimeUpdate={timeUpdate} style={{ display: "none" }} onEnded={() => setStarted(false)}>
                <source src={src} type="audio/mp3" />
            </audio>
            <div className='audio-player-wrapper'>
                <div className='audio-player' onClick={() => {
                    if (playing) player.current?.pause();
                    else player.current?.play();

                }}>
                    {!playing ? <div ><IoPlaySharp /></div> :
                        <div><IoPauseSharp /></div>}
                    {started && <>{convertTime(currentTime)}</>}
                    {!started && convertTime(duration)}
                </div>
                {started && <div className='audio-reset' onClick={() => {
                    player.current.currentTime = 0;
                    player.current.pause();
                    setStarted(false);
                }}><RxReset /></div>}
            </div>
            <motion.div className="audio-percentage-bar" animate={{ height: started ? 5 : 0, marginTop: started ? 10 : 0 }}><motion.div animate={{ width: `${percentage}%` }} className="audio-percentage-bar__indicator" ></motion.div></motion.div>
            {/* <div className='playbar'><div className='playbar__percentage' style={{ width: `${percentage}%` }} /></div> */}
        </>
    );
};

export default Audio;