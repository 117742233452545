import Hr from "~/components/Hr";
import ListenToText from "~/components/ListenToText";
import Box from "./Box";

import "./Insight.css";

const Insight = ({ title, subtitle, text, boxes, children, audio }) => {
    return (
        <>
            {title && <h1>{title}</h1>}
            {subtitle && <h2 className="h3 t-normal">{subtitle}</h2>}
            {children && !audio ? children : null}
            {/* <Hr y={20} /> */}
            {(text && audio) || (children && audio) ?
                <ListenToText audio={audio}>
                    <p className="ingress" >
                        {children}
                        {text && text?.map(t => <>{t}<br /><br /></>)}
                    </p>
                </ListenToText> : null
            }
            {boxes &&
                <div className={`insight-box-grid insight-box-grid--${boxes?.length}`}>
                    {boxes?.map(b => <Box title={b.title} p="1rem" wrapperProps={{ style: { height: "100%" } }}><ListenToText audio={b.audio}>{b.text}</ListenToText></Box>)}
                </div>
            }
        </>
    );
};

export default Insight;