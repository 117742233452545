
// Styles
import "./Oversikt.css";

// Internal components
import Box from "~/components/Box";
import A from "~/components/A";
import Circle from "~/components/Circle";

// External components
import { IoPlaySharp } from "react-icons/io5/index.esm.js";
import Btn from "~/components/Btn";
import Hr from "~/components/Hr";
import Cube from "~/components/Cube";
import { useContext, useRef } from "react";
import { DataContext } from "~/contexts/Data";

const Oversikt = () => {
    const { userData } = useContext(DataContext);
    const box1 = useRef();
    const box2 = useRef();
    const box3 = useRef();
    return (
        <div className="oversikt">
            <h1>Oversikt</h1>
            <div className="steps">
                <Box yellow vCenter center h100>
                    <A href="/forklaringsvideo" className="steps__play-link hover-parent">
                        <Circle size="4rem" className="hover-scale"><IoPlaySharp size="2.5rem" /></Circle>
                        <Hr y={15} />
                        <p className="h3 text-dark">Se introduksjonsvideo</p>
                    </A>
                </Box>
                <Box center style={{ height: "100%" }} success={userData?.trinn1?.done} h100 ref={box1} innerStyle={{ justifyContent: "flex-end" }}>
                    <Cube height={80} mt={-80} color={userData?.trinn1?.done ? "#A0DEBA" : "var(--yellow)"} className="hover-scale-effect" />
                    <Hr y={35} />
                    <p className="h3">1. Det visuelle i teksten</p>
                    {/* <p className="h4">1 modell<br />6 komponenter<br />8 oppgaver</p>
                        <p className="h4 t-bold">Premie: <br />Undervisningsopplegg</p> */}
                    <Btn circleArrow className="hover-scale" href="/trinn1/"
                        onMouseEnter={() => {
                            box1.current.classList.add("hover-parent-hovering");
                        }}
                        onMouseLeave={() => {
                            box1.current.classList.remove("hover-parent-hovering");
                        }}
                    >Start</Btn>
                </Box>
                <Box center success={userData?.trinn2?.done} h100 ref={box2} innerStyle={{ justifyContent: "flex-end" }}>
                    <Cube height={150} mt={-150} color={userData?.trinn2?.done ? "#A0DEBA" : "var(--yellow)"} className="hover-scale-effect" />
                    <Hr y={35} />
                    <p className="h3">2. Det språklige i teksten</p>
                    {/* <p className="h4">1 modell<br />6 komponenter<br />X oppgaver</p>
                        <p className="h4 t-bold">Premie: <br />Undervisningsopplegg</p> */}
                    <Btn circleArrow className="hover-scale" href="/trinn2/"
                        onMouseEnter={() => {
                            box2.current.classList.add("hover-parent-hovering");
                        }}
                        onMouseLeave={() => {
                            box2.current.classList.remove("hover-parent-hovering");
                        }}
                    >Start</Btn>
                </Box>
                <Box center success={userData?.trinn3?.done} h100 ref={box3} innerStyle={{ justifyContent: "flex-end" }}>
                    <Cube height={250} mt={-250} color={userData?.trinn3?.done ? "#A0DEBA" : "var(--yellow)"} className="hover-scale-effect" />
                    <Hr y={35} />
                    <p className="h3">3. Den sammensatte (multimodale) teksten</p>
                    {/* <p className="h4">1 modell<br />6 komponenter<br />X oppgaver</p>
                        <p className="h4 t-bold">Premie: <br />Undervisningsopplegg</p> */}

                    <Btn circleArrow className="hover-scale" href="/trinn3/"
                        onMouseEnter={() => {
                            box3.current.classList.add("hover-parent-hovering");
                        }}
                        onMouseLeave={() => {
                            box3.current.classList.remove("hover-parent-hovering");
                        }}
                    >Start</Btn>
                </Box>
            </div>
        </div>
    );
};

export default Oversikt;