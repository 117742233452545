
import Hr from '~/components/Hr';
import PartnerLogos from '~/components/PartnerLogos';

const About = () => {
    return (
        <>
            <div className='t-center'>
                <h1>
                    Læringsressursen<br />
                    er forskningsbasert
                </h1>
                <p>
                    Læringsressursen er utviklet på grunnlag av forskningsprosjektet{" "}
                    <a href="https://prosjektbanken.forskningsradet.no/project/FORISS/301347">Kritisk literacy i en digital og global verden.</a> av forskere ved Universitetet
                    i Sørøst-Norge og Universitetet i Agder. Prosjektet og læringsressursen er
                    finansiert av  Forskningsrådet.
                </p>
                <Hr y={60} border />
                <center><PartnerLogos /></center>
            </div>
        </>
    );
};

export default About;