
import { Suspense, useContext, useEffect, lazy } from 'react';

// Contexts
import { DataContext } from '~/contexts/Data';

// Internal components
import Menu from '~/components/Menu';
// import Bg from '~/components/Bg';

// External components
import Hotkeys from "react-hot-keys";
import AllRoutes from '~/components/AllRoutes';
import { useLocation } from 'react-router-dom';

const Dev = lazy(() => import("~/components/Dev"));

const App = () => {
    const { toggleDevStatus, devStatus, setNextTheme, setUserData } = useContext(DataContext);
    const { pathname } = useLocation();
    useEffect(() => {
        let body = document.body;
        body.style.transition = 'padding-left 150ms cubic-bezier(0.2, 0, 0.2, 1)';
        if (!devStatus) {
            body.style.paddingLeft = "0px";
        }
    }, [devStatus]);
    useEffect(() => {
        if (typeof setNextTheme === "function") {
            setNextTheme(null);
        }
    }, [pathname]);


    useEffect(() => {
        setUserData(d => {
            if (Array.isArray(d?.visited)) {
                let arr = new Set([...d.visited]);
                arr.add(pathname);
                return { ...d, visited: [...arr] };
            } else {
                let arr = new Set();
                arr.add(pathname);
                return { ...d, visited: [...arr] };
            }
        });
    }, [pathname]);

    return (
        <>
            <Hotkeys
                keyName='alt+shift+g'
                allowRepeat={true}
                onKeyDown={() =>
                    window
                        .open(`https://github.com/aksellsor/aks_react_tekstkritisk/actions`, '_blank')
                        .focus()
                }
            ></Hotkeys>
            <Hotkeys
                keyName='alt+shift+l'
                allowRepeat={true}
                onKeyDown={() =>
                    window
                        .open(`https://963548-nodejs.web.tornado-node.net/`, '_blank')
                        .focus()
                }
            ></Hotkeys>
            <Hotkeys
                keyName="ctrl+shift+d"
                allowRepeat={true}
                onKeyDown={() => toggleDevStatus(!devStatus)}
            />
            <Suspense fallback={<></>}>{devStatus && <Dev />}</Suspense>
            <Menu />
            <AllRoutes />
            {/* <Bg /> */}
            {/* <div className='logo-fixed-middle'><Logo /></div> */}
        </>
    );
};

export default App;