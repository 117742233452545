import { useState } from "react";

// Styles
import "./Cube.scss";

const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

const Cube = ({
    color = "#fff",
    front,
    back,
    right,
    left,
    top,
    bottom,
    x = 0,
    y = 0,
    mt, ml,
    translate,
    randomPos,
    randomness = 30,
    className,
    yRadius,
    xRadius,
    zRadius,
    priority,
    home,
    width = "5rem",
    height = "5rem",
    to = "",
    tooltip,
    frontColor,
    topColor,
    leftColor,
    rightColor,
    bottomColor,
    backColor,
    href,
    hrefAriaLabel,
    ...props
}) => {
    // const { x, y } = useMousePosition();
    if (randomPos && x === 0 && y === 0) {
        y = Math.trunc(Math.random(0, 1) * randomness - randomness / 2) + "vw";
        // y =
        //     Math.trunc((Math.random(0, 1) * randomness) / 2 - randomness / 4) +
        //     "vh";
        // z = Math.floor(Math.random() * randomness) + "vw";
    }
    const getHalfSize = (size) => {
        let arr = /([0-9]*)([a-z]*)/g.exec(size);
        return arr[1] / 2 + arr[2];
    };
    const getDividedSize = (size, divideBy) => {
        let arr = /([0-9]*)([a-z]*)/g.exec(size);
        return arr[1] / divideBy + arr[2];
    };
    const styles = {
        // Styles for faces
        // boxShadow: `0px 0px 2px ${color}`,
        // border: `1px solid ${color}`,
        width: width,
        height: height,
        fontSize: getDividedSize(width, 3),
    };
    const transform = {
        default: `rotateX(-25deg) rotateY(-45deg)`,
        hover: `rotateX(-25deg) rotateY(-45deg)`,
    };
    const [currentTransform, setCurrentTransform] = useState(transform.default);
    // const [facesText, setFacesText] = useState(false);
    const facesText = false;
    const scale = "scale(1.002)";
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <div
            className={`${className || ""} cube-container`}
            style={{
                marginTop: mt,
                marginLeft: ml,
                top: y,
                left: x,
                // position: (x || y) ? "absolute" : "relative",
                height: height,
                marginBottom: (x || y) ? "" : "20px",
                zIndex: priority,
                translate
                // marginBottom: `calc(${width} * 0.5)`,
                // zIndex: Math.ceil(parseFloat(y) * 0.1),
            }}
            onMouseOver={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            {...props}
        >
            <ConditionalWrapper
                condition={href}
                wrapper={children => <a href={href} aria-label={hrefAriaLabel}>{children}</a>}
            >
                <>
                    <div
                        className={`cube`}
                        onMouseOver={() => setCurrentTransform(transform.hover)}
                        onMouseLeave={() => setCurrentTransform(transform.default)}
                        style={{
                            transform: `${currentTransform} ${translate ? `translate(${translate})` : ""}`,
                            pointerEvents: "all",
                            position: "relative",
                            width: width,
                            height: height
                        }}
                    >
                        <div
                            className={`cube__face cube__face--front ${facesText ? "cube__face--front--text" : ""
                                }`}
                            style={{
                                ...styles,
                                transform: `${scale} translateZ(${getHalfSize(
                                    width
                                )})`,
                                backgroundColor: frontColor || frontColor || color,
                            }}
                        >
                            {front}
                        </div>
                        <div
                            className={`cube__face cube__face--back ${facesText ? "cube__face--back--text" : ""
                                }`}
                            style={{
                                ...styles,
                                width: width,
                                height: height,
                                transform: `rotateY(-180deg) ${scale} translateZ(${getHalfSize(
                                    width
                                )})`,
                                backgroundColor: backColor || frontColor || color,
                            }}
                        >
                            {back}
                        </div>
                        <div
                            className={`cube__face cube__face--right ${facesText ? "cube__face--right--text" : ""
                                }`}
                            style={{
                                ...styles,
                                width: width,
                                height: height,
                                transform: `rotateY(90deg) ${scale} translateX(${getHalfSize(
                                    width
                                )})`,
                                backgroundColor: rightColor || frontColor || color,
                            }}
                        >
                            {right}
                        </div>
                        <div
                            className={`cube__face cube__face--left ${facesText ? "cube__face--left--text" : ""
                                }`}
                            style={{
                                ...styles,
                                width: width,
                                height: height,
                                transform: `rotateY(-90deg) ${scale} translateX(-${getHalfSize(
                                    width
                                )})`,
                                backgroundColor: leftColor || frontColor || color,
                            }}
                        >
                            {left}
                        </div>
                        <div
                            className={`cube__face cube__face--top ${facesText ? "cube__face--top--text" : ""
                                }`}
                            style={{
                                ...styles,
                                width: width,
                                height: width,
                                transform: `rotateX(90deg) ${scale} translateY(-${getHalfSize(
                                    width
                                )})`,
                                backgroundColor: topColor || frontColor || color,
                            }}
                        >
                            {top}
                        </div>
                        <div
                            className={`cube__face cube__face--bottom ${facesText ? "cube__face--bottom--text" : ""
                                }`}
                            style={{
                                ...styles,
                                width: width,
                                height: height,
                                transform: `rotateX(-90deg) ${scale} translateY(${getHalfSize(
                                    width
                                )})`,
                            }}
                        >
                            {bottom}
                        </div>
                    </div>
                    {tooltip && (
                        <div
                            className={`cube-tooltip ${showTooltip ? "cube-tooltip-show" : ""
                                } ${parseInt(x) > 50 ? "cube-tooltip-right" : ""}`}
                        >
                            {tooltip}
                        </div>
                    )}
                </>
            </ConditionalWrapper>
        </div>
    );
};

export default Cube;