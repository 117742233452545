import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

const A = ({ href, aTag = null, ...props }) => {
    const sameOrigin = useCallback(
        (a, b) => {
            const urlA = new URL(a);
            const urlB = new URL(b);
            return urlA.origin === urlB.origin;
        },
        [],
    );
    const isExternalLink = useCallback(
        (url) => {
            if (url) {
                if (url.startsWith("http")) {
                    if (sameOrigin(window.location.href, url)) return false;
                }
                if (url.slice(0, 1) === "/" || url.slice(0, 1) === "#") {
                    return false;
                }
                else {
                    return true;
                }
            }
        },
        [sameOrigin],
    );
    const location = useLocation();
    const linkProps = {};
    if (href?.startsWith("/modal")) {
        linkProps.state = { previousLocation: location };
    }
    if (!isExternalLink(href)) {
        return (
            <Link
                to={href}
                // aria-label={`Go to ${href}`}
                {...props}
                {...linkProps}
            >
                {props.children}
            </Link>
        );
    } else {
        return (
            <a
                href={href}
                target={'_blank'}
                // aria-label={`Go to ${href}`}
                rel='noreferrer'
                {...props}
            >
                {props.children}
            </a>
        );
    }
};

export default A;