import { useContext, useEffect } from 'react';

// Internal components
import Btn from '~/components/Btn';
import ModelT from '~/components/ModelT';

// Styles
import "./Home.css";

// Internal components
import Hr from '~/components/Hr';

// Assets
import sketch from "~/assets/skisse/home.jpg";

// Contexts
import { DataContext } from '~/contexts/Data';

const Home = () => {
    useEffect(() => {
        document.body.style.setProperty("--sketch", `url(${sketch})`);
    }, []);
    const { nextRoute } = useContext(DataContext);

    return (
        <div className='home'>
            <ModelT step={8} className={"home__model"} />
            <Hr y={10} />
            <h1 style={{ textAlign: "center" }}>
                Kritisk tekstarbeid i klasserommet<br />
                - en læringsressurs for lærere
            </h1>
            <Hr y={20} />
            <Btn shadow center arrow href={nextRoute} aria-label="Kom i gang">Kom i gang</Btn>
        </div>
    );
};

export default Home;