import Insight from "~/components/Insight";

const Introduksjon = () => {
    return (
        <div>
            <Insight
                title="Introduksjon"
                subtitle="Les eller lytt til teksten"
                audio="/media/voice/introduksjon.mp3"
                text={["Et sentralt anliggende for all form for kritisk tekstarbeid er å øke oppmerksomheten mot hvem som står bak teksten som tekstprodusent og hva slags interesser denne tekstprodusenten kan ha. Med interesser mener vi her hva slags nytte eller fordel tekstprodusenten kan ha av den framstillingsmåten som er valgt. I våre teksteksempler er det trolig fotografiene som først får oppmerksomhet fra leseren Det er derfor relevant å spørre hvorfor de ulike bedriftene og organisasjonene har valgt å bruke akkurat disse fotografiene. Hvilke interesser ligger bak?"]}
            />
        </div>
    );
};

export default Introduksjon;