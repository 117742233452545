import Box from "~/components/Box";

import sketch from "~/assets/skisse/before-you-start.jpg";
import { useEffect } from "react";
const BeforeYouStart = () => {
    useEffect(() => {
        document.body.style.setProperty("--sketch", `url(${sketch})`);
    }, []);
    return (
        <div style={{ margin: "auto" }}>
            <center><h1>Før du begynner</h1></center>
            <Box p="1.5rem">
                <p><strong>I denne l&aelig;ringsressursen pr&oslash;ver du ut arbeidsm&aring;ter som ogs&aring; kan brukes i klasserommet. Arbeidsm&aring;tene bygger p&aring; en helhetlig modell for kritisk tekstarbeid i klasserommet.</strong></p>
                <p>Ressursen best&aring;r av fire deler; en introduksjonsvideo og tre trinn der du pr&oslash;ver ut ulike arbeidsm&aring;ter. De ulike trinnene vektlegger ulike trekk ved tekstene. </p>
                <ul>
                    <li>Trinn 1: det visuelle i teksten</li>
                    <li>Trinn 2: det spr&aring;klige i teksten</li>
                    <li>Trinn 3: den sammensatte (multimodale) teksten</li>
                </ul>
                <p>Du kan selv velge om du vil l&oslash;se oppgavene individuelt eller i et l&aelig;rerteam. For hvert trinn du gjennomf&oslash;rer f&aring;r du tilgang til et undervisningsopplegg som kan brukes i klasserommet.</p>
                <p>F&oslash;r vi begynner f&aring;r du se en oversikt over hva du skal gjennom i denne digitale l&aelig;ringsressursen.</p>
            </Box>
        </div>
    );
};

export default BeforeYouStart;