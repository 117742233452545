
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import Hr from "./Hr";

const QR = ({ subtitle, title, val = "" }) => {
    const { value } = useParams();
    return (
        <div
        // style={{ width: 500 }}
        >
            <center>
                {title && <h1 className="mb-0">{title}</h1>}
                {subtitle &&
                    <>
                        <Hr border y={20} />
                        <p>{subtitle}</p>
                        <Hr y={10} />
                    </>
                }
            </center>
            <center>
                <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "450px", width: "100%" }}
                    value={val}
                    viewBox={`0 0 256 256`}
                />
            </center>
        </div>
    );
};

export default QR;