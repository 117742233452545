
// Styles
import A from "./A";
import "./Btn.css";

// External components
import { BsChevronLeft, BsChevronRight } from "react-icons/bs/index.esm.js";
import Circle from "~/components/Circle";
import { motion } from "framer-motion";


const Btn = ({ wrapperProps, children, className = "", iconLeft = null, p, shine, lg, sm, shadow = false, center = false, regular = false, arrow = false, back = false, circleArrow = false, style,
    theme // dark
    ,
    ...props }) => {
    if (center) className += " btn--center";
    if (regular) className += " btn--regular";
    if (shadow) className += " btn--shadow";
    if (lg) className += " btn--lg";
    if (sm) className += " btn--sm";
    if (shine) className += " btn--shine";
    if (circleArrow) className += " btn--circle-arrow";
    if (theme) className += ` btn--${theme}`;
    if (props.href?.length > 0) {
        return (
            <A className={`btn ${className}`} style={{ ...style, padding: p }}{...props}>{iconLeft ? <span className="btn__icon-left">{iconLeft}</span> : null}{back && <BsChevronLeft />}{children}{arrow && <BsChevronRight />}</A>
        );
    } else {
        return (
            <div className={`btn ${className}`} style={{ ...style, padding: p }}{...props}>{iconLeft ? <span className="btn__icon-left">{iconLeft}</span> : null}{back && <BsChevronLeft />}{children}{arrow && <BsChevronRight />}{circleArrow && <Circle shadow size="2em"><BsChevronRight size="1em" /></Circle>}</div>
        );
    }
};

export default Btn;