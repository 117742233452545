
// Styles
import "./ReadCard.css";
import Box from './Box';
import Btn from './Btn';
import { IoIosPrint } from "react-icons/io/index.esm.js";
import { IoScan } from "react-icons/io5/index.esm.js";
import { GrDocumentPdf } from "react-icons/gr/index.esm.js";
import A from "./A";
import { motion } from "framer-motion";
import Hr from "./Hr";
// import { useEffect, useState } from "react";

export const ReadCard = ({ lgImg, img, title, children, print, doc, qr, success }) => {
    // const [tabFocused, setTabFocused] = useState(null);
    // const onFocus = () => {
    //     setTabFocused(true);
    // };

    // // User has switched away from the tab (AKA tab is hidden)
    // const onBlur = () => {
    //     setTabFocused(false);
    // };

    // useEffect(() => {
    //     window.addEventListener("focus", onFocus);
    //     window.addEventListener("blur", onBlur);
    //     // Calls onFocus when the window first loads
    //     onFocus();
    //     // Specify how to clean up after this effect:
    //     return () => {
    //         window.removeEventListener("focus", onFocus);
    //         window.removeEventListener("blur", onBlur);
    //     };
    // }, []);

    // useEffect(() => {
    //     if (tabFocused) {
    //         document.querySelectorAll(".pdfIframe").forEach(iframe => document.body.removeChild(iframe));
    //     }
    // }, [tabFocused]);

    return (
        <Box p="1rem" titleMargin=".5rem" className="t-center readcard" success={success} center style={{ height: "auto" }} title={
            <div>
                {img && <A href={`/modal/lightbox?img=${lgImg?.src || img?.src}`} ><img src={img?.src} {...img} className={`read-card__thumb hover-scale rounded shadow ${img?.className || ""}`} /></A >}
                <Hr y={10} />
                <strong>{title}</strong>
            </div>
        }>
            {children}
            <motion.div animate={{
                height: success ? 0 : "auto",
            }} initial={false} className="w-100" style={{ overflow: "hidden" }}>
                <div className='read-card__buttons w-100'>
                    {doc && <Btn href={`/modal/iframe?src=${doc}`} p=".5rem .75rem" theme="white" iconLeft={<GrDocumentPdf size="1em" />}>Åpne dokument</Btn>}
                    {(doc && print) && <Btn onClick={() => {
                        var printWindow = window.open(doc);
                        printWindow.print();

                        //Close window once print is finished
                        printWindow.onafterprint = function () {
                            printWindow.close();
                        };
                        // function printPdf(url) {
                        //     var iframe = document.createElement('iframe');
                        //     // iframe.id = 'pdfIframe'
                        //     iframe.className = 'pdfIframe';
                        //     document.body.appendChild(iframe);
                        //     iframe.style.display = 'none';
                        //     iframe.onload = function () {
                        //         setTimeout(function () {
                        //             iframe.focus();
                        //             iframe.contentWindow.print();
                        //             URL.revokeObjectURL(url);
                        //             // document.body.removeChild(iframe)
                        //         }, 1);
                        //     };
                        //     iframe.src = url;
                        //     // URL.revokeObjectURL(url);
                        // }
                        // printPdf(print);
                    }} p=".5rem .75rem" theme="white" iconLeft={<IoIosPrint size="1em" />}>Skriv ut dokument</Btn>}
                    {qr && <Btn href={qr} p=".5rem .75rem" iconLeft={<IoScan size="1em" />}>Se på mobil <small>(QR-kode)</small></Btn>}
                </div>
            </motion.div>
        </Box>
    );
};
