// Styles
import Timestamp from "~/components/Timestamp";
import "./ForklaringsVideo.css";

// Internal components
import Box from "~/components/Box";

// Fetched using https://rapidapi.com/benrhzala90/api/youtube-transcriptor/
import * as text from "./ForklaringsVideoData.json";
import useSize from "@react-hook/size";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
const ForklaringsVideo = () => {
    const videoWrapper = useRef();
    const video = useRef();
    const [width, height] = useSize(videoWrapper);
    const [player, setPlayer] = useState(null);
    useLayoutEffect(() => {
        const tag = document.createElement('script');
        tag.src = 'https://player.vimeo.com/api/player.js';

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }, []);
    const scrollBox = useRef();

    let chapters = [
        { num: "1.", timeCode: "00:00", seconds: 1, label: "Intro" },
        // { num: "2.", timeCode: "01:41", seconds: 101, label: "Multippel lesning" },
        { num: "2.", timeCode: "03:32", seconds: 212, label: "Rundt teksten" },
        // { num: "4.", timeCode: "05:10", seconds: 310, label: "Tekstreaksjon" },
        { num: "3.", timeCode: "06:10", seconds: 370, label: "I teksten" },
        // { num: "6.", timeCode: "08:59", seconds: 539, label: "Teksttolkning" },
        { num: "4.", timeCode: "12:33", seconds: 753, label: "Ut i samfunnet" },
    ];
    const [time, setTime] = useState(0);
    const [playing, setPlaying] = useState(null);
    useEffect(() => {
        if (player) {
            player.on('play', () => setPlaying(true));
            player.on('pause', () => setPlaying(false));
            return () => {
                player.off('pause', () => setPlaying(null));
                player.off('play', () => setPlaying(null));
            };
        }
    }, [player]);

    useEffect(() => {
        const getTime = setInterval(() => {
            if (playing) {
                player?.getCurrentTime().then(function (seconds) {
                    setTime(seconds);
                });
            }
        }, 100);
        return () => {
            clearInterval(getTime);
        };
    }, [player, playing]);

    return (
        <div className="forklaringsvideo">
            <div>
                <h2 className="h1">Introduksjonsvideo</h2>
                {/* <pre>{JSON.stringify({ time, playing }, null, 1)}</pre> */}
                <Box
                    p={"0"}
                    ref={scrollBox}
                    style={{ height: height }}
                    scrollY
                >
                    {player !== null && chapters?.map(c => {
                        let last = chapters?.filter(ch => time >= ch.seconds)?.pop();
                        // let minutes = parseInt(c.timeCode.split(":")[0]);
                        // let seconds = parseInt(c.timeCode.split(":")[1]);
                        // let total = (minutes * 60) + seconds;
                        return (
                            <div className={`forklaringsvideo__transcript-item ${last === c ? "forklaringsvideo__transcript-item--current" : ""}`}
                                onClick={() => {
                                    player.setCurrentTime(c.seconds);
                                    player?.getCurrentTime().then(function (seconds) {
                                        setTime(seconds);
                                    });
                                    player.play();
                                    // player.seekTo(timecode);
                                    // document.querySelectorAll(".forklaringsvideo__transcript-item").forEach(el => el.classList.remove("forklaringsvideo__transcript-item--current"));
                                    // r.current.classList.add("forklaringsvideo__transcript-item--current");
                                    // if (timecodeIsplaying && !hoveringBox) {
                                    //     setTimeout(() => {
                                    //         r.current.classList.remove("forklaringsvideo__transcript-item--current");
                                    //     }, 1000);
                                    // }
                                }}>
                                <span className="forklaringsvideo__transcript-item__label">
                                    <strong>{c.num}</strong>
                                    <span>{c.label}</span>
                                </span>
                                <Timestamp>
                                    {c.timeCode}
                                </Timestamp>
                            </div>);
                    }
                    )}
                </Box>
            </div>
            <div>
                <p className="h1">&nbsp;</p>
                <div ref={videoWrapper} className="forklaringsvideo__video-wrapper rounded shadow overflow-hidden">
                    {/* <div ref={video} className="forklaringsvideo__video" /> */}
                    <iframe
                        onLoad={() => {
                            if (window.Vimeo) {
                                let iframe = document.querySelector(".forklaringsvideo__video");
                                const p = new window.Vimeo.Player(iframe);
                                setPlayer(p);
                            }
                        }}
                        className="forklaringsvideo__video"
                        src="https://player.vimeo.com/video/860810839?h=1147226bef&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;quality=1080p"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        title="Kritisk tekstarbeid"
                    ></iframe>

                </div>
            </div>
        </div>
    );
};

export default ForklaringsVideo;