import { useState } from "react";
import Hr from "./Hr";
import Audio from "./Audio";

const ListenToText = ({ children, audio }) => {
    const [percentage, setPercentage] = useState(null);
    return (
        <>
            <div className="listen-to-text">
                <Audio src={audio} returnPercentage={setPercentage} />
                <Hr y={20} />
                <div className="audio-percentage" >
                    {children}
                </div>
            </div>
        </>
    );
};

export default ListenToText;