
// Styles
import "./Box.css";

import { IoIosCheckmarkCircle } from "react-icons/io/index.esm.js";
import { RiFullscreenLine } from "react-icons/ri/index.esm.js";
import { motion } from "framer-motion";
import { useContext, useEffect, useRef, useState } from "react";
import { DataContext } from "~/contexts/Data";

// External components
const Box = ({ wrapperProps, title, h100, autoHeight = false, children, success, wide, overflow, rounded = true, titleMargin = "1rem", className, yellow, vCenter, center, p, style = {}, scrollY, noOverflow, outline, fullscreen, innerStyle = {}, ...props }) => {
    const { setHideFooter, setHideHeader } = useContext(DataContext);
    if (vCenter) className += " box--v-center";
    if (center) className += " box--center";
    if (scrollY) className += " box--scroll-y";
    if (noOverflow) className += " box--no-overflow";
    if (success) className += " box--success";
    if (outline) className += " box--outline";
    if (wide) className += " box--wide";
    if (overflow) className += " box--overflow";
    if (autoHeight) style.height = "max-content";
    const [boxExpanded, setBoxExpanded] = useState(false);
    useEffect(() => {
        setHideFooter(boxExpanded);
        setHideHeader(boxExpanded);
    }, [boxExpanded]);

    const inner = useRef();
    useEffect(() => {
        if (boxExpanded) {
            document.querySelector(".box--expanded textarea")?.focus();
        }
    }, [boxExpanded]);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                setBoxExpanded(false);
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);
    return (
        <motion.div
            initial={{
                y: -20,
            }}
            animate={{
                y: 0,
            }}
            {...wrapperProps}
            onClick={e => {
                e.stopPropagation();
                if (e.target.classList.contains("close")) {
                    setBoxExpanded(false);
                }
            }} className={`box-wrapper close ${boxExpanded ? "box-wrapper--expanded" : ""} `} >
            <div
                className={`box ${fullscreen ? "box--fullscreen" : ""} ${boxExpanded ? "box--expanded" : ""} ${className || ""} ${rounded ? "box--rounded" : ""} ${yellow ? "box--yellow" : ""}`}
                style={{ "--p": p, ...style }}
                {...props}
            >
                {fullscreen &&
                    <div
                        className="box__fullscreen-btn"
                        onClick={() => setBoxExpanded(e => !e)}
                    >
                        <motion.div
                            className="box__fullscreen-btn-inner"
                            whileTap={{ scale: 0.9 }}
                        >
                            <RiFullscreenLine />
                        </motion.div>
                    </div>
                }
                {success && <IoIosCheckmarkCircle className="box--success__icon" />}
                {title &&
                    <>
                        <div className="box__title" style={{
                            padding: p,
                            // marginBottom: 0,
                            marginBottom: titleMargin || p,
                            paddingBottom: titleMargin || p, paddingTop: titleMargin || p
                        }}><strong>{title}</strong></div>
                    </>
                }
                <div ref={inner} className={`box__inner`} style={{ ...innerStyle, padding: p, paddingTop: `${title ? "0px" : p}`, height: h100 ? "100%" : innerStyle?.height }} >{children}</div>

            </div>
        </motion.div>
    );
};

export default Box;