
// Styles
import { useContext, useCallback, useEffect, useState, useRef } from "react";
import "./Menu.css";
import { DataContext } from "~/contexts/Data";
import Btn from "./Btn";
import A from "./A";
import Hr from "./Hr";

// External components
import { VscChromeClose } from "react-icons/vsc/index.esm.js";
import { BsSearch } from "react-icons/bs/index.esm.js";
import { useClickAway } from "@uidotdev/usehooks";
import { useInViewport } from 'react-in-viewport';
import parse from "html-react-parser";
import { RiDeleteBin6Line } from "react-icons/ri/index.esm.js";
import { IoIosCheckmarkCircle } from "react-icons/io/index.esm.js";

import * as searchDataJSON from "../data/searchData.json";
// import { useDebounce } from 'usehooks-ts';

import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const MenuItem = ({ r, index, q, gridSize, showUrl }) => {
    const { userData } = useContext(DataContext);
    const item = useRef();
    const {
        inViewport,
        enterCount
    } = useInViewport(
        item,
        {},
        { disconnectOnLeave: false },
    );
    let regex = new RegExp(`(${q})`, 'gi');
    let title = r.path?.replace(regex, "<span class='h'>$1</span>");
    let success = userData?.visited?.includes(r.path);
    return (
        <motion.div whileTap={{ scale: 0.95 }} ref={item} >
            <A className={`menu__item ${success ? "menu__item--visited" : ""}`}
                key={index + "menu-item"}
                href={r.path}
                aria-label={`Gå til ${r.path}`}
                style={{ width: `${gridSize}px` }}
            >
                {success && <IoIosCheckmarkCircle className="menu__item__checked-icon" />}
                {/* {gridSize > 250 && <small>{parse(title)}</small>} */}
                {showUrl && <span style={{ marginBottom: ".25rem" }}><small>{parse(title)}</small></span>}
                <span className="menu__item__number"> {r.index}</span>

                {r.thumb ? <div className="menu__item-thumb">
                    <motion.img className="menu__item-thumb-img" alt={`Thumbnail for ${r.path}`} initial={{ opacity: 0 }} animate={{ opacity: enterCount > 0 ? 1 : 0 }}
                        src={r.thumb} loading="lazy" />
                </div> : gridSize <= 250 && <small>{parse(title)}</small>}
            </A>
        </motion.div>
    );
};

const Menu = () => {
    // let searchData = JSON.parse(searchDataJSON);
    const { menu, setMenu, routes, useStickyState, userData, resetUserData } = useContext(DataContext);
    const [routesWithSearchData, setRoutesWithSearchData] = useState(null);
    useEffect(() => {
        // console.log(typeof searchDataJSON?.default === "object");
        if (routes?.length > 0 && typeof searchDataJSON?.default === "object") {
            setRoutesWithSearchData(routes?.map(obj => { return { ...obj, searchData: searchDataJSON?.default[obj.path] }; }));
        }
    }, [routes, searchDataJSON]);

    const closeMenu = useCallback(
        () => {
            setMenu(false);
        },
        [menu],
    );
    const menuRef = useClickAway(closeMenu);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                closeMenu();
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const [searchQuery, setSearchQuery] = useStickyState("", "menus-search-query");
    // const searchQuery = useDebounce(searchQuery, 100);
    const [filtered, setFiltered] = useStickyState(null, "filtered-menu-items");
    const [itemstoShow, setItemstoShow] = useStickyState(null, "shown-menu-items");

    useEffect(() => {
        setFiltered(routesWithSearchData);
    }, [routesWithSearchData]);

    const [filters, setFilters] = useStickyState([], "menu-filters");

    useEffect(() => {
        if (filters?.length > 0) {
            let f = routesWithSearchData?.filter(r => {
                let toMatch = r.path?.toLowerCase();
                let match = filters.filter(f => toMatch.includes(f));
                return match?.length > 0;
            });
            setFiltered(f);
        } else {
            setFiltered(routesWithSearchData);
        }
    }, [filters]);

    useEffect(() => {
        if (searchQuery?.length > 0) {
            // let f = filtered?.filter(r => r.path?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
            // console.log(filtered);
            let f = filtered?.filter(r => JSON.stringify(r).toLowerCase()?.includes(searchQuery?.toLowerCase()));
            setItemstoShow(f);
        } else {
            setItemstoShow(filtered);
        }
    }, [filtered, searchQuery]);

    // const [cols, setCols] = useState(4);
    const [gridSize, setGridSize] = useStickyState(5, "menu-grid-item-size");
    const menuGrid = useRef();
    // const [width, height] = useSize(menuGrid);
    // useEffect(() => {
    //     setGridSize(width * 0.95 / (10 - cols));
    // }, [cols, width]);


    const [grouped, setGrouped] = useState(null);
    useEffect(() => {
        if (itemstoShow?.length > 0) {
            let groups = ['/trinn1', '/trinn2', '/trinn3'];
            let obj = { '/intro': [], '/trinn1': [], '/trinn2': [], '/trinn3': [] };
            itemstoShow?.forEach((item, index) => {
                groups?.forEach(g => {
                    if (item.path.startsWith(g)) obj[g].push({ ...item, index: index + 1 });
                });
                let f = (i) => item.path.startsWith(groups[i]);
                if (!f(0) && !f(1) && !f(2)) {
                    obj["/intro"].push({ ...item, index: index + 1 });
                }
            });
            setGrouped(obj);
        }
        else {
            setGrouped(null);
        }
    }, [itemstoShow]);

    const { pathname } = useLocation();
    useEffect(() => {
        closeMenu();
    }, [pathname]);

    const [showUrl, setShowUrl] = useState(null);
    return (
        <div className={`menu-wrapper ${menu ? "menu-wrapper--open" : ""}`} >
            {menu && <div className="menu" ref={menuRef}>
                <Btn className="menu__close" onClick={() => closeMenu()}><VscChromeClose /></Btn>
                <div style={{ gridColumn: "1/-1", marginRight: "4rem" }} className="t-center menu__header">
                    {/* <pre>{JSON.stringify(routes?.length, null, 1)}</pre>
                    <pre>{JSON.stringify(filtered?.length, null, 1)}</pre>
                    <pre>{JSON.stringify(itemstoShow?.length, null, 1)}</pre> */}
                    <div className="menu-row">
                        <label className="menu__search">
                            <input type="search" defaultValue={searchQuery}
                                placeholder="Trykk Enter for å søke"
                                onChange={e => {
                                    if (e.target.value.length === 0) {
                                        setSearchQuery(e.target.value);
                                    }
                                }}
                                onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        setSearchQuery(e.target.value);
                                    }
                                }}
                            /><BsSearch />
                        </label>
                        {/* <pre>{JSON.stringify({ width, cols, gridSize }, null, 1)}</pre> */}
                        <Btn theme={showUrl ? "success" : ""} onClick={() => { setShowUrl(s => !s); }} className="max-content-width">Vis url</Btn>
                        <label id="zoom"> <div>Størrelse</div><input aria-labelledby="zoom" type="range" min={1} max={6} step={1} value={gridSize} onChange={e => setGridSize(e.target.value)} /></label>
                        {/* <input type="range" min={1} max={7} step={1} value={cols} onChange={e => setCols(e.target.value)} /> */}
                        {/* <input type="range" min={100} max={500} step={25} value={gridSize} onChange={e => setGridSize(e.target.value)} /> */}
                        <Btn theme={`${filters?.includes("trinn1") ? "success" : ""}`} onClick={() => filters?.includes("trinn1") ? setFilters(arr => arr.filter(f => f !== "trinn1")) : setFilters(arr => [...arr, "trinn1"])}>Trinn1</Btn>
                        <Btn theme={`${filters?.includes("trinn2") ? "success" : ""}`} onClick={() => filters?.includes("trinn2") ? setFilters(arr => arr.filter(f => f !== "trinn2")) : setFilters(arr => [...arr, "trinn2"])}>Trinn2</Btn>
                        <Btn theme={`${filters?.includes("trinn3") ? "success" : ""}`} onClick={() => filters?.includes("trinn3") ? setFilters(arr => arr.filter(f => f !== "trinn3")) : setFilters(arr => [...arr, "trinn3"])}>Trinn3</Btn>
                    </div>
                    {(userData?.visited?.length > 1) &&
                        <>
                            <Hr y={10} />
                            <Btn sm theme="danger" style={{ marginLeft: "1rem" }} onClick={(e) => {
                                e.stopPropagation();
                                if (window.confirm("Er du sikker på at du vil slette all progresjonen din?")) {
                                    resetUserData();
                                    // navigate("/");
                                    // closeMenu();
                                }
                            }}><RiDeleteBin6Line />Slett min progresjon</Btn>
                            {/* <Hr y={20} /> */}
                        </>
                    }
                </div>
                {/* {filters?.length > 0 || searchQuery?.length > 0 ?
                    <motion.div initial={{ y: -30 }} animate={{ y: 0 }}>
                        <Hr y={20} />
                        <center>
                            <Btn sm onClick={() => {
                                setSearchQuery("");
                                setFilters([]);
                            }}>Fjern valg</Btn>
                        </center>
                    </motion.div>
                    : null} */}
                <div className="menu__inner scrollbar" ref={menuGrid}>
                    {!grouped && <center className="t-danger h3 t-normal">
                        Ingen treff på
                        {searchQuery ? <> søkeord: <em><strong>"{searchQuery}"</strong></em></> : null}
                        <Hr y={10} />
                        {filters?.length > 0 ? <div> innenfor <span style={{ display: "inline-flex", flexWrap: "wrap", gap: ".5rem" }}>{filters?.map(f => <span className="tag">{f}</span>)}</span></div> : null}
                    </center>}
                    {grouped && Object.entries(grouped)?.map((keyVal, parentIndex) => keyVal[1]?.length > 0 &&
                        <div className={`menu__group ${!keyVal[1]?.length > 0 ? "menu__group--disabled" : ""}`} key={JSON.stringify(keyVal[1])}>
                            <div className="h3 menu-header-row">{keyVal[0]} <Hr border y={20} /></div>
                            <div className="menu__group-grid"
                                // style={{ width: "100%" }}
                                style={{ gridTemplateColumns: `repeat(${10 - gridSize},1fr)` }}
                            // style={{ gridTemplateColumns: `repeat(auto-fit, minmax(${gridSize}px, 1fr))` }}
                            >
                                {
                                    keyVal[1]?.map((r, index) => !r.modal &&
                                        <div onClick={() => {
                                            if (r.path == window.location.pathname) {
                                                closeMenu();
                                            }
                                        }}>
                                            <MenuItem r={r} index={`${parentIndex}.${index + 1}`} q={searchQuery} showUrl={showUrl}
                                                key={r.path + "menu-item"}
                                            // gridSize={gridSize}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )}
                    {/* {itemstoShow?.map((r, index) => !r.modal &&
                        <>
                            {!searchQuery?.length > 0 &&
                                <>
                                    {r.path === "/" && <div className="h2 menu-header-row">Intro <Hr border y={50} /></div>}
                                    {r.path === "/trinn1/" && <div className="h2 menu-header-row">Trinn 1 <Hr border y={50} /></div>}
                                    {r.path === "/trinn2/" && <div className="h2 menu-header-row">Trinn 2 <Hr border y={50} /></div>}
                                    {r.path === "/trinn3/" && <div className="h2 menu-header-row">Trinn 3 <Hr border y={50} /></div>}
                                </>
                            }
                            <MenuItem r={r} index={index} q={searchQuery} />
                        </>
                    )} */}
                </div>
            </div>}
        </div>
    );
};

export default Menu;