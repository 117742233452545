// Styles
import "./Timestamp.css";

// External components
import { IoPlaySharp } from "react-icons/io5/index.esm.js";

const Timestamp = ({ children, ...props }) => {
    return (
        <span className='timestamp' {...props}><IoPlaySharp />{children}</span>
    );
};

export default Timestamp;