
import Lottie from "lottie-react";
import { useRef } from "react";
import Btn from "../../components/Btn";
import { FiArrowUpLeft } from "react-icons/fi/index.esm.js";
const NotFound = () => {
    const lottie = useRef();
    const options = {
        loop: true,
        autoplay: true,
        lottieRef: lottie,
        path: "/media/sorry.json",
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div className="not-found">
            <Lottie {...options} className="not-found-lottie" />
            <h1>Denne siden finnes ikke</h1>
            <center><Btn regular href={"/"} ><FiArrowUpLeft /> Forsiden</Btn></center>
        </div>
    );
};

export default NotFound;