// Styles
import { useEffect, useState } from "react";
import "./Loading.css";
import { motion } from "framer-motion";

const Delayed = ({ children, waitBeforeShow }) => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsShown(true);
        }, waitBeforeShow);
        return () => clearTimeout(timer);
    }, [waitBeforeShow]);

    return isShown ? children : null;
};

const Loading = ({ delay = 0 }) => {
    return (
        <Delayed waitBeforeShow={delay}>
            <div className="loading-wrapper">
                <motion.div className='loading' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1 }}>
                    <svg className="loading__symbol" xmlns="http://www.w3.org/2000/svg" width="43.481" height="50.207" viewBox="0 0 43.481 50.207">
                        <path className="loading__symbol__bg" d="M81.557,80.781v-25.1L59.816,43.126,38.076,55.677v25.1L59.816,93.333Z" transform="translate(-38.076 -43.126)" />
                        <path className="loading__symbol__text" d="M79.305,61.937V68.5L75.285,64.1H69.65V81.29l2.443,1.727H60.861L63.3,81.29V64.1H57.669L53.649,68.5V61.937l3.468.585H75.837Z" transform="translate(-44.729 -47.373)" />
                    </svg>
                </motion.div>
            </div>
        </Delayed>
    );
};

export default Loading;