
const Personvern = () => {
    return (
        <>
            <h1>Personvern</h1>

            <p> Det eneste som lagres av data er det du selv skriver inn og svarer på i løsningen. <br />
                Dette lagres kun lokalt på din egen nettleser slik at den husker det til neste gang du besøker nettsiden.</p>

            <p>Alle din lagrede "progresjon", kan slettes ved å trykke på menyknappen oppe til høyre, <br />og velge "Slett min progresjon".</p>

            <p> Vi lagrer ingen informasjon som gjør at vi kan identifisere deg som person. <br />
                Vi samler likevel inn avidentifiserte opplysninger om besøket ditt gjennom verktøyet Google Analytics. Formålet med dette er å utarbeide statistikk som vi bruker til å forbedre og videreutvikle nettsiden.
                Eksempel på hva statistikken gir svar på, er hvor mange som besøker ulike sider på nettstedet, hvor lenge besøket varer og hvilke nettlesere som blir brukt.
            </p>
        </>
    );
};

export default Personvern;