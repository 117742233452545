// Styles
import { useEffect, useRef, useState } from "react";
import "./ModelT.scss";
import { motion } from "framer-motion";

const ModelT = ({ step = 0, className, size = "250px", checkmarks = true, singleStep = false, animateInCheckmarks = true, showDoneColor = true, stepsArr, ...props }) => {
  const [steps, setSteps] = useState([
    {
      colored: true,
      checked: true
    },
    {
      colored: false,
      checked: false
    },
    {
      colored: false,
      checked: false
    },
    {
      colored: false,
      checked: false
    },
    {
      colored: false,
      checked: false
    },
    {
      colored: false,
      checked: false
    },
  ]);
  // const getStep = useCallback(
  //   (index, key) => {@
  //     if (Array.isArray(steps) && steps[index]) {
  //       return steps[index][key];
  //     }
  //   },
  //   [steps],
  // );
  let currentStep = typeof step === "function" ? step() : step;
  const from = {};
  const to = {};
  let max = 8;
  const getColored = (step) => {
    return singleStep ? (currentStep === step) : ((!checkmarks && currentStep > step) || currentStep === step || currentStep === max || (!showDoneColor && currentStep >= step));
  };
  const getDone = (step) => {
    return singleStep && checkmarks ? (currentStep === step) : (checkmarks && currentStep > step && currentStep < max);
  };
  const m = useRef();
  const m1 = useRef();
  const m2 = useRef();
  const m3 = useRef();
  const m4 = useRef();
  const m5 = useRef();
  const m6 = useRef();
  const mText = useRef();
  const mTextBg = useRef();

  useEffect(() => {
    let M = m.current;
    if (M) {
      if (stepsArr?.includes(1) || getColored(1)) { m1?.current && M.appendChild(m1.current); }
      if (stepsArr?.includes(2) || getColored(2)) { m2?.current && M.appendChild(m2.current); }
      if (stepsArr?.includes(3) || getColored(3)) { m3?.current && M.appendChild(m3.current); }
      if (stepsArr?.includes(4) || getColored(4)) { m4?.current && M.appendChild(m4.current); }
      if (stepsArr?.includes(5) || getColored(5)) { m5?.current && M.appendChild(m5.current); }
      if (stepsArr?.includes(6) || getColored(6)) { m6?.current && M.appendChild(m6.current); }
      mTextBg?.current && M.appendChild(mTextBg.current);
      mText?.current && M.appendChild(mText.current);
    }
  }, [m]);

  const checkmarkFrom = { scale: 0 };
  const checkmarkTo = { scale: 1 };
  return (
    <>
      <motion.svg
        initial={{ scale: 0.95 }} animate={{ scale: 1 }}
        {...props} shape-rendering="crispEdges"
        key={currentStep + "model"}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 187.5 213.6"
        width={size}
        height={size}
        className={` ${className} model-t ${currentStep === max ? "model-t--done" : ""} ${!checkmarks ? "model-t--no-checkmarks" : ""}`}
      >
        <defs>
          <linearGradient id="header-shape-gradient" x2="0.35" y2="1">
            <stop offset="0%" stopColor="var(--color-stop)" />
            <stop offset="30%" stopColor="var(--color-stop)" />
            <stop offset="100%" stopColor="var(--color-bot)" />
          </linearGradient>
        </defs>
        <g className="model" ref={m}>
          <path className="t-frame" d="m185.7,152.6V61c0-4.8-2.6-9.3-6.7-11.7L100.4,3.6c-4.1-2.4-9.2-2.4-13.4,0L8.4,49.3c-4.1,2.4-6.7,6.9-6.7,11.7v91.6c0,4.8,2.6,9.3,6.7,11.7l78.6,45.8c4.1,2.4,9.2,2.4,13.4,0l78.6-45.8c4.1-2.4,6.7-6.9,6.7-11.7Z" />
          <motion.path ref={m1} initial={from} animate={to} transition={{ duration: .2 }} className={`t-step-1 t-step ${stepsArr?.includes(1) ? "t-step--colored" : getColored(1) ? "t-step--colored" : ""} ${getDone(1) && showDoneColor ? "t-step--done" : ""}`} d="m93.6,58h0l-42.4,24.5h0L3.1,54.7c1-2,2.6-3.7,4.6-4.9L87.7,3.3c1.8-1.1,3.9-1.6,5.9-1.6v56.4Z" />
          <motion.path ref={m2} initial={from} animate={to} transition={{ duration: .3 }} className={`t-step-2 t-step ${stepsArr?.includes(2) ? "t-step--colored" : getColored(2) ? "t-step--colored" : ""} ${getDone(2) && showDoneColor ? "t-step--done" : ""}`} d="m184.1,54.7s0,0,0,0l-48.1,27.8h0s-42.4-24.5-42.4-24.5h0s0,0,0,0V1.7h0c2.1,0,4.1.5,5.9,1.6l80,46.6c2,1.1,3.5,2.8,4.6,4.8Z" />

          <motion.path ref={m3} initial={from} animate={to} transition={{ duration: .4 }} className={`t-step-3 t-step ${stepsArr?.includes(3) ? "t-step--colored" : getColored(3) ? "t-step--colored" : ""} ${getDone(3) && showDoneColor ? "t-step--done" : ""}`} d="m184.1,54.7c.9,1.7,1.4,3.6,1.4,5.5v93.2c0,2.1-.5,4-1.5,5.8,0,0,0,0,0,0l-48-27.7h0s0,0,0,0v-49h0s48.1-27.8,48.1-27.8c0,0,0,0,0,0Z" />
          <motion.path ref={m4} initial={from} animate={to} transition={{ duration: .5 }} className={`t-step-4 t-step ${stepsArr?.includes(4) ? "t-step--colored" : getColored(4) ? "t-step--colored" : ""} ${getDone(4) && showDoneColor ? "t-step--done" : ""}`} d="m136,131.5l48,27.7s0,0,0,0c-1,1.9-2.6,3.5-4.4,4.6l-80,46.6c-1.8,1.1-3.9,1.6-5.9,1.6h0v-56.1h0s0,0,0,0l42.4-24.5h0s0,0,0,0Z" />

          <motion.path ref={m5} initial={from} animate={to} transition={{ duration: .6 }} className={`t-step-5 t-step ${stepsArr?.includes(5) ? "t-step--colored" : getColored(5) ? "t-step--colored" : ""} ${getDone(5) && showDoneColor ? "t-step--done" : ""}`} d="m93.6,156v56.1h0c-2,0-4.1-.5-5.9-1.6L7.7,163.8c-1.9-1.1-3.4-2.7-4.5-4.6l48-27.7h0l42.4,24.5h0Z" />
          <motion.path ref={m6} initial={from} animate={to} transition={{ duration: .7 }} className={`t-step-6 t-step ${stepsArr?.includes(6) ? "t-step--colored" : getColored(6) ? "t-step--colored" : ""} ${getDone(6) && showDoneColor ? "t-step--done" : ""}`} d="m51.2,82.5v49L3.2,159.2c-.9-1.7-1.5-3.7-1.5-5.8V60.3c0-1.9.5-3.8,1.3-5.5l48.1,27.8Z" />

          <path ref={mTextBg} className="t-text-bg" d="m136,131.5v-49l-42.4-24.5-42.4,24.5v49l42.4,24.5,42.4-24.5Z" />
          <path ref={mText} className="t-text" d="m117.4,81.4v16.4l-7.5-11h-10.4v42.9l4.5,4.3h-20.8l4.5-4.3v-42.9h-10.4l-7.5,11v-16.4l6.4,1.5h34.7l6.4-1.5Z" />
        </g>
        {checkmarks &&
          <g className="t-checkmarks">
            <g id="t1-check" className={`t-checkmark ${getDone(1) ? "t-checkmark--checked" : ""} ${animateInCheckmarks ? "t-checkmark--should-animate" : ""}`}>
              <circle id="t1-outer-circle" className="t-outer-circle" cx="58.3" cy="49.2" r="13.3" />
              <path id="t1-circle" className="t-circle" d="m58.2,39.9c5.1,0,9.3,4.1,9.3,9.3s-4.1,9.3-9.3,9.3-9.3-4.1-9.3-9.3c0-5.1,4.1-9.3,9.3-9.3h0Z" />
              <path id="t1-icon" className="t-icon" d="m56.6,52.2l-2.7-2.7c-.2-.2-.2-.4,0-.6l.6-.6c.2-.2.4-.2.6,0l1.8,1.8,3.9-3.9c.2-.2.4-.2.6,0l.6.6c.2.2.2.4,0,.6l-4.8,4.8c-.2.2-.4.2-.6,0Z" />
            </g>
            <g id="t2-check" className={`t-checkmark ${getDone(2) ? "t-checkmark--checked" : ""} ${animateInCheckmarks ? "t-checkmark--should-animate" : ""}`}>
              <circle id="t2-outer-circle" className="t-outer-circle" cx="130.5" cy="49.2" r="13.3" />
              <path id="t2-circle" className="t-circle" d="m130.4,39.9c5.1,0,9.3,4.1,9.3,9.3s-4.1,9.3-9.3,9.3-9.3-4.1-9.3-9.3c0-5.1,4.1-9.3,9.3-9.3h0Z" />
              <path id="t2-icon" className="t-icon" d="m128.8,52.2l-2.7-2.7c-.2-.2-.2-.4,0-.6l.6-.6c.2-.2.4-.2.6,0l1.8,1.8,3.9-3.9c.2-.2.4-.2.6,0l.6.6c.2.2.2.4,0,.6l-4.8,4.8c-.2.2-.4.2-.6,0Z" />
            </g>
            <g id="t3-check" className={`t-checkmark ${getDone(3) ? "t-checkmark--checked" : ""} ${animateInCheckmarks ? "t-checkmark--should-animate" : ""}`}>
              <circle id="t2-outer-circle-2" className="t-outer-circle" cx="160.7" cy="106.8" r="13.3" />
              <path id="t2-circle-2" className="t-circle" d="m160.6,97.5c5.1,0,9.3,4.1,9.3,9.3s-4.1,9.3-9.3,9.3-9.3-4.1-9.3-9.3c0-5.1,4.1-9.3,9.3-9.3h0Z" />
              <path id="t2-icon-2" className="t-icon" d="m159,109.8l-2.7-2.7c-.2-.2-.2-.4,0-.6l.6-.6c.2-.2.4-.2.6,0l1.8,1.8,3.9-3.9c.2-.2.4-.2.6,0l.6.6c.2.2.2.4,0,.6l-4.8,4.8c-.2.2-.4.2-.6,0Z" />
            </g>
            <g id="t4-check" className={`t-checkmark ${getDone(4) ? "t-checkmark--checked" : ""} ${animateInCheckmarks ? "t-checkmark--should-animate" : ""}`}>
              <circle id="t2-outer-circle-3" className="t-outer-circle" cx="130.5" cy="162.1" r="13.3" />
              <path id="t2-circle-3" className="t-circle" d="m130.4,152.8c5.1,0,9.3,4.1,9.3,9.3s-4.1,9.3-9.3,9.3-9.3-4.1-9.3-9.3c0-5.1,4.1-9.3,9.3-9.3h0Z" />
              <path id="t2-icon-3" className="t-icon" d="m128.8,165.1l-2.7-2.7c-.2-.2-.2-.4,0-.6l.6-.6c.2-.2.4-.2.6,0l1.8,1.8,3.9-3.9c.2-.2.4-.2.6,0l.6.6c.2.2.2.4,0,.6l-4.8,4.8c-.2.2-.4.2-.6,0Z" />
            </g>
            <g id="t5-check" className={`t-checkmark ${getDone(5) ? "t-checkmark--checked" : ""} ${animateInCheckmarks ? "t-checkmark--should-animate" : ""}`}>
              <circle id="t2-outer-circle-4" className="t-outer-circle" cx="58.3" cy="162" r="13.3" />
              <path id="t2-circle-4" className="t-circle" d="m58.2,152.7c5.1,0,9.3,4.1,9.3,9.3s-4.1,9.3-9.3,9.3-9.3-4.1-9.3-9.3c0-5.1,4.1-9.3,9.3-9.3h0Z" />
              <path id="t2-icon-4" className="t-icon" d="m56.6,165l-2.7-2.7c-.2-.2-.2-.4,0-.6l.6-.6c.2-.2.4-.2.6,0l1.8,1.8,3.9-3.9c.2-.2.4-.2.6,0l.6.6c.2.2.2.4,0,.6l-4.8,4.8c-.2.2-.4.2-.6,0Z" />
            </g>
            <g id="t6-check" className={`t-checkmark ${getDone(6) ? "t-checkmark--checked" : ""} ${animateInCheckmarks ? "t-checkmark--should-animate" : ""}`}>
              <circle id="t2-outer-circle-5" className="t-outer-circle" cx="26.5" cy="107.7" r="13.3" />
              <path id="t2-circle-5" className="t-circle" d="m26.3,98.4c5.1,0,9.3,4.1,9.3,9.3s-4.1,9.3-9.3,9.3-9.3-4.1-9.3-9.3c0-5.1,4.1-9.3,9.3-9.3h0Z" />
              <path id="t2-icon-5" className="t-icon" d="m24.8,110.7l-2.7-2.7c-.2-.2-.2-.4,0-.6l.6-.6c.2-.2.4-.2.6,0l1.8,1.8,3.9-3.9c.2-.2.4-.2.6,0l.6.6c.2.2.2.4,0,.6l-4.8,4.8c-.2.2-.4.2-.6,0Z" />
            </g>
          </g>
        }
      </motion.svg>
    </>
  );
};

export default ModelT;