import ReactDOM from 'react-dom/client';
import '~/styles/main.scss';
import DataProvider from '~/contexts/Data.jsx';

import {
  BrowserRouter,
} from "react-router-dom";
import App from './App';
import { Suspense } from 'react';
import Loading from './components/Loading';



ReactDOM.createRoot(document.getElementById('root')).render(
  <Suspense fallback={<Loading />}>
    <DataProvider>
      <BrowserRouter><App />
      </BrowserRouter>
    </DataProvider >
  </Suspense>,
);
